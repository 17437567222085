import styled, { css } from 'styled-components';
import { zIndex } from 'config/styles';
import { models } from './constants';

export const Container = styled.div`
  min-width: 100px;
  position: relative;
  width: 100%;
`;

export const ItemSelected = styled.div`
  justify-content: center;
  color: ${({ theme, model }) => (model === models.modelB ? theme.colors.text.w000 : theme.colors.text.w600)};
  font-size: ${({ theme }) => theme.fontSize.sm};
  cursor: pointer;
  width: 100%;
  height: 45px;
  background: ${({ theme, model }) => (model === models.modelB ? 'transparent' : theme.colors.input.w100)};
  border: ${({ theme, model }) => (model === models.modelB ? `2px solid ${theme.colors.secondary.w100}` : 'none')};
  padding: 10px 15px;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  ${(props) => (props.position === 'top'
    ? css`
          border-radius: ${props.open ? '0 0 20px 20px' : '50px'};
          `
    : css`
          border-radius: ${props.open ? '20px 20px 0 0' : '50px'};
        `)};

  ${(props) => (props.model === models.modelB
    && css`
      &:after {
        background: ${props.theme.colors.secondary.w200};
        border: 2px solid;
        border-bottom: 0;
        border-right: 0;
        border-color: ${props.theme.colors.secondary.w100};
        content: '';
        display: block;
        height: 12px;
        position: absolute;
        top: -7px;
        transform: rotate(45deg);
        width: 12px;
      }
    `
  )};
`;

export const ComboBox = styled.div`
  transition: all 100ms ease;
  cursor: pointer;
  position: absolute;
  width: 100%;
  ${(props) => (props.position === 'top'
    ? css`
          bottom: 95%;
          border-radius: 20px 20px 0 0;
          padding-top: ${props.open ? '10px' : '0'};
        `
    : css`
          top: 95%;
          border-radius: 0 0 20px 20px;
          padding-bottom: ${props.open ? '10px' : '0'};
        `)};
  max-height: ${(props) => (props.open ? '50vh' : '0')};
  overflow-y: auto;
  color: #ccc;
  z-index: ${zIndex.lv1};
  ::placeholder {
    color: ${({ theme }) => theme.colors.text.w000};
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 23px;
  }
  ::-webkit-scrollbar-thumb {
    background: #797979 0% 0% no-repeat padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.secondary.w300};
  }

  ${(props) => (props.model === models.modelA && css`
    background: ${props.theme.colors.input.w100};
    border: none;
  `)}

  ${(props) => (props.model === models.modelB && css`
    background: ${props.theme.colors.secondary.w200};
    border: ${({ open }) => open && '2px solid'};
    border-color: ${({ theme }) => theme.colors.secondary.w100};
    border-bottom: none;
    box-shadow: 0 -20px 35px -10px #343434b8;
  `)}
`;

export const Option = styled.div`
  align-items: center;
  display: block;
  height: 40px;
  overflow: hidden;
  padding: 10px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${(props) => (
    props.model === models.modelA && css`
      background: ${props.theme.colors.input.w100};
      color: ${({ theme }) => theme.colors.text.w600};
      &:hover {
        background: ${({ theme }) => theme.colors.secondary.w050};
      }
    `
  )}
  ${(props) => (
    props.model === models.modelB && css`
      background: ${props.theme.colors.secondary.w200};
      color: ${({ theme }) => theme.colors.text.w000};
      &:hover {
        background: ${({ theme }) => theme.colors.secondary.w100};
      }
    `
  )}
`;

export const Arrow = styled.div`
  border: 1px solid;
  border-color: ${({ theme, model }) => (model === models.modelB ? theme.colors.secondary.w050 : theme.colors.secondary.w300)};
  border-width: 0 3px 3px 0;
  cursor: pointer;
  display: inline-block;
  padding: 6px;
  transition: transform 0.1s;
  position: absolute;
  right: 20px;
  
  ${(props) => (props.position === 'top'
    ? css`
      top: ${props.open ? '13px' : '20px'};
      transform: ${props.open ? 'rotate(45deg)' : 'rotate(-135deg)'};
      `
    : css`
      top: ${props.open ? '20px' : '13px'};
      transform: ${props.open ? 'rotate(-135deg)' : 'rotate(45deg)'};
    `
  )}
`;

export const Placeholder = styled.span`
  color: ${({ theme }) => theme.colors.text.w400};
`;

export const TextEllipsed = styled.span`
    display: block;
    overflow: hidden;
    padding: 0px 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
