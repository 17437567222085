import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GlobalStyle } from 'config/globalStyle';
import store from 'config/store';
import 'services/translate';
import App from './App';

function Wrapper() {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <App />
    </Provider>
  );
}

ReactDOM.render(<Wrapper />, document.getElementById('root'));
