import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

import { models, states } from './constants';

import { Container } from './styles';

export default function Index({
  text, onClick, width, model, type, id, margin, state,
}) {
  return (
    <Container
      data-testid={`button-${text}`}
      id={id}
      onClick={onClick}
      width={width}
      model={model}
      type={type}
      margin={margin}
      state={state}
    >
      {
        state === states.loading
          ? <Loading type="loading" circle />
          : text
      }
    </Container>
  );
}

Index.propTypes = {
  id: PropTypes.string,
  margin: PropTypes.string,
  model: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
};

Index.defaultProps = {
  id: '',
  margin: 'initial',
  model: models.modelA,
  onClick: null,
  state: states.default,
  text: '',
  type: 'button',
  width: '100%',
};

export { models, states };
