import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: ${({ height }) => height || '80vh'};
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
`;

export const Img = styled.img`
  width: 80px;
  margin-right: 30px;
`;
