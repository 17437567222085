import { names } from 'components/Icon/constants';
import { moduleActions } from './constants';

const INITIAL_STATE = {
  active: {},
  list: [
    {
      id: 0,
      name: 'Monitor',
      displayName: 'module.monitor',
      navMenu: {
        active: {},
        list: [
          {
            id: 0,
            name: 'menu.dashboard',
            icon: {
              name: names.dashboard,
              alt: 'dashboard do monitor',
            },
            componentName: 'Dashboard',
          },
          {
            id: 1,
            name: 'menu.history',
            icon: {
              name: names.history,
              alt: 'histórico do monitor',
            },
            componentName: 'HistoryMonitor',
          },
          {
            id: 2,
            name: 'menu.alarms',
            icon: {
              name: names.alert,
              alt: 'alarmes',
            },
            componentName: 'AlarmLevel',
          },
          {
            id: 3,
            name: 'menu.configERP',
            icon: {
              name: names.adjust,
              alt: 'configuração frequência PEP',
            },
            componentName: 'ParamsSendERP',
          },
          {
            id: 4,
            name: 'menu.sendERP',
            icon: {
              name: names.erp,
              alt: 'envio ao pep',
            },
            componentName: 'ValidateVItalSigns',
          },
        ],
      },
    },
    {
      id: 1,
      name: 'Air',
      displayName: 'module.air',
      navMenu: {
        active: {},
        list: [
          {
            id: 0,
            name: 'menu.dashboard',
            icon: {
              name: names.dashboard,
              alt: 'dashboard do air',
            },
            componentName: 'Ventilator',
          },
        ],
      },
    },
    {
      id: 2,
      name: 'Infusion',
      displayName: 'module.infusion',
      navMenu: {
        active: {},
        list: [
          {
            id: 0,
            name: 'menu.dashboard',
            icon: {
              name: names.dashboard,
              alt: 'dashboard de infusão',
            },
            componentName: 'InfusionPump',
          },
        ],
      },
    },
  ],
  keepCurrentActiveModule: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case moduleActions.CHANGE_ACTIVE_MODULE:
      return { ...state, active: action.payload };
    case moduleActions.CHANGE_ACTIVE_MENU_ITEM:
      return {
        ...state,
        active: {
          ...state.active,
          navMenu: {
            ...state.active.navMenu,
            active: action.payload,
          },
        },
      };
    case moduleActions.TOGGLE_KEEP_ORIGIN:
      return { ...state, keepCurrentActiveModule: action.payload };
    default:
      return state;
  }
}
