import { loginActions, configActions } from 'config/constants';
import { backOff } from 'exponential-backoff';
import { logger } from 'services/cloudWatch';
import api from 'services/api';

import { loginStatus } from './constants';

/*
  logout()
  @description: Exit session
  @params: null
  @response: null
*/

export const logoutLocal = () => {
  window.localStorage.removeItem('dataHistory');
  window.localStorage.removeItem('auth');
  window.localStorage.removeItem('indicatorsVentilator');
  window.location.href = '/';
};

/*
  decodeToken()
  @description: Authentication user login
  @params: token
  @response: Username and password
*/

export const decodeToken = (token) => async (dispatch) => {
  const isDemo = (process.env.REACT_APP_IS_DEMO === 'true');

  try {
    let data;
    api.interceptors.request.use((config) => ({ ...config, headers: { Authorization: `Bearer ${token}` } }));

    if (isDemo) {
      import('services/api/mock/http')
        .then((modules) => {
          data = modules.decode;
          dispatch({ type: configActions.SET_USER_LOGIN, payload: data });
        });
    } else {
      const request = await api.get('/api/v2/user/authorization/token/decode');
      data = request.data.data;
      dispatch({ type: configActions.SET_USER_LOGIN, payload: data });
    }

    const res = await api.get(`/api/v2/hospital/${process.env.REACT_APP_HOSPITALID}`);
    dispatch({ type: configActions.SET_USEERP, payload: res?.data?.data?.['use-erp'] ?? false });
    dispatch({ type: configActions.SET_AUTH });
  } catch (err) {
    dispatch(logoutLocal());
  }
};

export const logout = () => async (dispatch) => {
  try {
    await api.get('/api/v2/logout');
    dispatch(logoutLocal());
  } catch (err) {
    dispatch(logoutLocal());
  }
  dispatch({ type: loginActions.LOGIN_STATUS, payload: 'idle' });
};

/*
  fetchLogin()
  @description: Triggers error message for the user
  @params: token and status
  @response: Message authentication
*/

export const fetchLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: loginActions.LOGIN_STATUS, payload: loginStatus.running });
    const timeStamp = new Date().toLocaleString();
    const request = await backOff(() => api.post('/api/v2/login/', data), {
      numOfAttempts: 5,
      startingDelay: 200,
      maxDelay: 2000,
      retry(error, attemptNumber) {
        logger.onError(error, {
          location: 'Login',
          retries: attemptNumber,
          timestamp: timeStamp,
        });
        return true;
      },
    });
    const { token, decode } = request.data.data;

    api.interceptors.request.use((config) => ({ ...config, headers: { Authorization: `Bearer ${token}` } }));

    window.localStorage.setItem('auth', token);
    const res = await api.get(`/api/v2/hospital/${process.env.REACT_APP_HOSPITALID}`);
    dispatch({ type: configActions.SET_USEERP, payload: res?.data?.data?.['use-erp'] ?? false });
    dispatch({ type: configActions.SET_USER_LOGIN, payload: decode });
    dispatch({ type: loginActions.LOGIN_STATUS, payload: loginStatus.success });
  } catch (err) {
    dispatch({ type: loginActions.LOGIN_STATUS, payload: loginStatus.error });
    logger.onError(err, {
      location: 'Login',
      timestamp: new Date().toLocaleString(),
    });

    if (err.response === undefined) {
      dispatch({
        type: loginActions.SET_ERROR,
        payload: 'Erro no servidor inesperado',
      });
    } else {
      const { status } = err.response;
      if (status >= 500) {
        dispatch({
          type: loginActions.SET_ERROR,
          payload: `#${status}. Erro no servidor inesperado`,
        });
      } else {
        const message = err?.response?.data?.errors?.message;
        const code = err?.response?.data?.errors?.code;
        const payload = (message && code) ? `${code}: ${message}` : status;

        dispatch({
          type: loginActions.SET_ERROR,
          payload,
        });
      }
    }
  }
};

/*
  setError()
  @description: Dispatch error type on network
  @params: null
  @response: null
*/

export const setError = (error) => (dispatch) => {
  dispatch({
    type: loginActions.SET_ERROR,
    payload: error,
  });
};

/*
  setErrorForgotPassword()
  @description: Dispatch error type on Forgot Password
  @params: null
  @response: null
*/

export const setErrorForgotPassword = (error) => (dispatch) => {
  dispatch({
    type: loginActions.SET_ERROR_FORGOT_PASSWORD,
    payload: error,
  });
};

/*
  setForgotPasswordSent()
  @description: Dispatch ok when email sent
  @params: null
  @response: null
*/

export const setForgotPasswordSent = (ok) => (dispatch) => {
  dispatch({
    type: loginActions.SET_FORGOT_PASSWORD_SENT,
    payload: ok,
  });
};

/*
  fetchForgotPassword()
  @description: Triggers error message for the user
  @params: token and status
  @response: Message authentication
*/

export const fetchForgotPassword = (data) => async (dispatch) => {
  try {
    const request = await api.post('/api/v2/user-forgot-password', data);

    const { forgotPassword, email } = request.data.data;

    if (forgotPassword) {
      if (email) {
        dispatch(setForgotPasswordSent({ text: 'feedback.weSentAnEmailToRecoverYourPasswordTo', email }));
      } else dispatch(setForgotPasswordSent({ text: 'feedback.weSentAnEmailToRecoverYourPassword', email: '' }));
    } else dispatch(setErrorForgotPassword('feedback.professionalIdNotExist'));
  } catch (err) {
    // console.log(err);
    if (err.response === undefined) {
      dispatch(setErrorForgotPassword('Erro no servidor inesperado'));
    } else {
      const { status, data } = err.response;
      if (status >= 500) {
        dispatch(setErrorForgotPassword(data.errors[0].error));
      } else if (status === 404) {
        dispatch(setErrorForgotPassword('Serviço não encontrado'));
      } else {
        dispatch(setErrorForgotPassword('feedback.professionalIdNotExist'));
      }
    }
  }
};

export const toggleShowLogoutModal = (payload) => ({
  type: loginActions.TOGGLE_LOGOUT_MODAL,
  payload,
});
