import styled from 'styled-components';
import { breakpoints, zIndex } from 'config/styles';

export const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.secondary.w200};
  z-index: ${zIndex.lv1};
  
  @media (min-width: ${breakpoints.xs}) {
    padding: 13px 50px 10px 50px;
  }
`;

export const Logo = styled.img`
  display: none;
  height: 30px;
  margin-right: 15px;
  margin-top: 10px;

  @media (min-width: ${breakpoints.sm}) {
    display: block;
  }
`;

export const FooterRight = styled.div`
  align-items: center;
  display: flex;
  grid-column: 3;
  grid-row: 2;
  justify-content: flex-end;

  
  > img {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
  
  @media (min-width: ${breakpoints.xs}) {
    grid-column: unset;
    grid-row: unset;
  }
`;

export const FooterLeft = styled.div`
  grid-column: 1;
  grid-row: 2;
  
  @media (min-width: ${breakpoints.xs}) {
    grid-column: unset;
    grid-row: unset;
  }
`;

export const FooterCenter = styled.div`
  grid-column: span 3;
  grid-row: 1;
  
  @media (min-width: ${breakpoints.xs}) {
    grid-column: unset;
    grid-row: unset;
  }
`;

export const FooterIcon = styled.div`
  margin-right: 15px;

  path {
    fill: #fff;
  }
`;

export const FooterSideIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
  path {
    fill: #fff;
  }
`;

export const FooterIconAlign = styled.div`
  margin-right: 0.5em;
`;

export const IconTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  font-size: 15px;
  background: ${({ theme }) => theme.colors.secondary.w100};
  border: transparent;
  text-indent: 40%;
  color: ${({ theme }) => theme.colors.text.w000};
  &:focus {
    outline: none;
  }
`;

export const FooterMain = styled.div`
  color: ${({ theme }) => theme.colors.text.w000};
`;

export const NumberVersion = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};;
  color: ${({ theme }) => theme.colors.text.w000};
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

export const FooterGrid = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 250px 1fr;
  }
`;
