import { vitalTypes } from 'config/constants';

export function returnHighestStatus(statusA, statusB) {
  if (statusA === 'normal' && statusB === 'normal') return 'normal';
  if (statusA === 'danger' || statusB === 'danger') return 'danger';
  return 'warning';
}

export function mergeArterialPressureSSVV(vitalsigns) {
  const systolic = vitalsigns.find((ssvv) => ssvv.type === vitalTypes.SYSTOLIC_ARTERIAL_PRESSURE);
  const diastolic = vitalsigns.find((ssvv) => ssvv.type === vitalTypes.DIASTOLIC_ARTERIAL_PRESSURE);

  const status = returnHighestStatus(systolic?.status, diastolic?.status);

  const value = `${systolic?.value || ''}/${diastolic?.value || ''}`;

  return {
    type: 'BLOOD_PRESSURE_2',
    scale: 'mmHg',
    status,
    value,
    lacking: systolic?.lacking && diastolic?.lacking,
  };
}
