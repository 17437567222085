import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { bedTypes, vitalTypes } from 'config/constants';

import { vitalSignDictionary } from 'services/vitalSignDictionary';
import { getActiveModulesForSickbed, useCheckVitalSignAge } from 'services/utils';
import { useTranslation } from 'react-i18next';

import IconFree from '__assets/img/livre.png';
import IconOnHolding from '__assets/img/em-espera.png';
import IconInactive from '__assets/img/desativado.png';
import Icon from 'components/Icon';
import ERPShortCut from 'components/ERPShortCut';

import { mergeArterialPressureSSVV } from './utils';
import { setVitalSignToBed } from '../actions';

import {
  ContainerBed,
  Header,
  Body,
  BedNull,
  BedNullImg,
  BedNullTitle,
  BedNumber,
  Initials,
  Pid,
  VitalSign,
  TextVitalSign,
  ScaleVitalSign,
} from './styles';

const sequenceVitalSignals = [
  vitalTypes.HEART_RATE,
  vitalTypes.RESPIRATORY_RATE,
  'BLOOD_PRESSURE_2',
  'ARTERIAL_PRESSURE',
  vitalTypes.OXYGEN_SATURATION,
  vitalTypes.TEMPERATURE]; // FC,FR, PAS/D, PAM, SATO2, TEMP, GLIC

function Bed({
  data, permissions, alert, showDebugData = false,
}) {
  const [bedItem, setBedItem] = useState({ ...data });
  const { t } = useTranslation();

  useEffect(() => {
    setBedItem(data);
  }, [data]);

  function bedStatusType(status) {
    const obj = {
      title: '',
      img: '',
    };

    switch (status.toUpperCase()) {
      case bedTypes.INACTIVE:
        obj.title = t('bedStatus.disabled');
        obj.img = IconInactive;
        return obj;
      case bedTypes.FREE:
        obj.title = t('bedStatus.free');
        obj.img = IconFree;
        return obj;
      case bedTypes.ON_HOLDING:
        obj.title = t('bedStatus.onHolding');
        obj.img = IconOnHolding;
        return obj;
      case bedTypes.DANGER:
        obj.title = t('bedStatus.errorPID');
        obj.img = IconOnHolding;
        return obj;
      default:
        return obj;
    }
  }

  const {
    _id, status, name, initials, pid, patientId, vitalsigns, commFailure,
  } = bedItem;

  const {
    hasMonitor,
    hasVentilator,
    hasPump,
  } = getActiveModulesForSickbed(bedItem);
  const urlDest = `/bed/${_id}/${pid || 'null'}/${patientId || 'null'}`;

  const blood = mergeArterialPressureSSVV(vitalsigns);

  if (vitalsigns.map((vi) => vi.type).indexOf('BLOOD_PRESSURE_2') === -1) {
    if (blood.lacking !== undefined) {
      vitalsigns.push(blood);
    }
  }

  const vitalSignsFiltered = vitalsigns.filter((v) => {
    if (
      v.type === vitalTypes.SYSTOLIC_ARTERIAL_PRESSURE
      || v.type === vitalTypes.DIASTOLIC_ARTERIAL_PRESSURE
      || v.type === vitalTypes.BLOOD_PRESSURE

    ) {
      return false;
    }
    return true;
  })
    .map((sign) => ({ ...sign, sequence: sequenceVitalSignals.indexOf(sign.type) }))
    .sort((a, b) => {
      if (a.sequence < b.sequence) return -1;
      if (a.sequence > b.sequence) return 1;
      return 0;
    });

  const dispatch = useDispatch();

  const emptyPayload = {
    requestID: '',
    messageDateTime: '',
    patient: {
      _id: patientId,
      sickbed: {
        _id,
      },
    },
    devices: [
      {
        equipments: [
          {
            vitalSigns: [],
            id: null,
          },
        ],
        manufacturer: '',
        model: '',
        address: null,
      },
    ],
  };

  const { ageInSeconds } = useCheckVitalSignAge({
    expireAfterSeconds: 90,
    onExpiredCallFunction: () => dispatch(setVitalSignToBed(emptyPayload)),
    vitalsigns,
  });

  return (
    <ContainerBed
      data-bed-monitor={hasMonitor}
      data-bed-ventilator={hasVentilator}
      data-bed-pump={hasPump}
      data-bed-body={status}
      data-id={_id}
      data-sickbed-name={name}
      data-status={status}
      status={status}
    >
      {
        showDebugData && <div style={{ color: 'white', position: 'absolute' }}>{ageInSeconds}</div>
      }
      {
        (status === bedTypes.ACTIVE) && (permissions.sickbed_get) ? (
          <Link to={urlDest}>
            <Header data-header={initials} alert={alert}>
              <Initials data-initials={!initials ? t('empty') : initials}>
                {!initials ? t('empty') : initials}
              </Initials>
              <BedNumber
                data-sickbed-name={name && name.substring(0, 6)}
              >
                {name && name.substring(0, 6)}
              </BedNumber>
            </Header>
          </Link>
        ) : (
          <Header data-header={initials} alert={alert}>
            <Initials data-initils={!initials ? t('empty') : initials}>
              {!initials ? t('empty') : initials}
            </Initials>
            <BedNumber
              data-sickbed-name={name && name.substring(0, 6)}
            >
              {name && name.substring(0, 6)}
            </BedNumber>
          </Header>
        )
      }
      <Body>
        {status === bedTypes.ACTIVE ? (
          <>
            <Pid data-pid={pid && `#${pid}`}>{pid && `#${pid}`}</Pid>
            <ERPShortCut url={urlDest} />
            <div>
              {vitalSignsFiltered.map((v) => {
                const key = new Date() + v.type;
                return (
                  <VitalSign
                    data-vital-sign={v.type}
                    data-vital-sign-id={_id}
                    data-bed-vital-sign={name}
                    data-commfailure={!!commFailure}
                    data-lacking={!!v.lacking}
                    key={key}
                    alarm={v.status}
                    commFailure={!!commFailure}
                    lacking={!!v.lacking}
                  >
                    <Icon width="35px" height="35px" name={vitalSignDictionary(v.type)} />
                    <TextVitalSign data-sign-value={v.value} data-sign-scale={v.scale}>
                      {
                        commFailure || v.lacking ? '- -' : v.value
                      }
                      <ScaleVitalSign>
                        {
                          // commFailure || v.lacking ? '' : v.scale
                        }
                      </ScaleVitalSign>
                    </TextVitalSign>
                  </VitalSign>
                );
              })}
            </div>
          </>
        ) : (
          <BedNull>
            <BedNullTitle data-free-bed={status}>{bedStatusType(status).title}</BedNullTitle>
            <BedNullImg
              src={bedStatusType(status).img}
              alt={bedStatusType(status).title}
            />
          </BedNull>
        )}
      </Body>
    </ContainerBed>
  );
}

export default Bed;
