import { actions } from './actions';

const init = {
  equipments: {},
  loadingEquipments: true,
  medications: [],
  loadingMedications: true,
  updatingMedication: false,
};

export default function reducer(state = init, { type, equipments, medications }) {
  switch (type) {
    case actions.SET_MEDICATION_START:
      return { ...state, updatingMedication: true };
    case actions.SET_MEDICATION_DONE:
    case actions.SET_MEDICATION_ERROR:
      return { ...state, updatingMedication: false };

    case actions.GET_MEDICATIONS_DONE:
      return { ...state, loadingMedications: false, medications };
    case actions.GET_MEDICATIONS_ERROR:
      return { ...state, loadingMedications: false, equipments: init.equipments };

    case actions.GET_EQUIPMENTS_START:
      return { ...state, loadingEquipments: true };
    case actions.GET_EQUIPMENTS_DONE:
      return { ...state, loadingEquipments: false, equipments };
    case actions.GET_EQUIPMENTS_ERROR:
      return { ...state, loadingEquipments: false, equipments: init.equipments };

    default:
      return state;
  }
}
