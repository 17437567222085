import { breakpoints, zIndex } from 'config/styles';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  backdrop-filter: blur(8px);
  background: ${({ theme }) => theme.colors.secondary.w400};
  display: flex;
  gap: 22px;
  height: 30px;
  justify-content: center;
  padding: 0 50px;
  position: relative;
  width: 100%;
  z-index: ${zIndex.lv2};
  
  @supports (backdrop-filter: blur(8px)) {
    background: #252625d4;
  }
  
  @media (min-width: ${breakpoints.xs}) {
    justify-content: right;
    position: fixed;
    top: 0;
  }
`;
