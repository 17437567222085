import { loadingStatus, resolutions, HistoryMonitorActions } from './constants';

const INITIAL_STATE = {
  indicators: [
    {
      id: 1,
      typeIndicator: 'respiratoryRate',
      name: 'ssvv.respiratoryRate',
      color: '#FFD200',
      units: 'rpm',
      initials: 'abbreviation.respiratoryRate',
      isEnabled: true,
    },
    {
      id: 2,
      typeIndicator: 'heartRate',
      name: 'ssvv.heartRate',
      color: '#68c568',
      units: 'bpm',
      initials: 'abbreviation.heartRate',
      isEnabled: true,
    },
    {
      id: 3,
      typeIndicator: 'o2Saturation',
      name: 'ssvv.oxygenSaturation',
      color: '#c51fc5',
      units: '%',
      initials: 'abbreviation.oxygenSaturation',
      isEnabled: true,
    },
    {
      id: 4,
      typeIndicator: 'temperature',
      name: 'ssvv.temperature',
      color: '#e21e1e',
      units: '°C',
      initials: 'abbreviation.temperature',
      isEnabled: true,
    },
    {
      id: 5,
      typeIndicator: 'bloodPressureSystolic',
      name: 'ssvv.systolicArterialPressure',
      units: 'mmHg',
      initials: 'abbreviation.systolicArterialPressure',
      color: '#567ff3',
      isEnabled: true,
    },
    {
      id: 6,
      typeIndicator: 'bloodPressureDiastolic',
      name: 'ssvv.diastolicArterialPressure',
      units: 'mmHg',
      initials: 'abbreviation.diastolicArterialPressure',
      color: '#567ff3',
      isEnabled: true,
    },
  ],
  patientData: {
    id: '',
    name: 'ssvv.',
    age: null,
  },
  date: '',
  metrics: [],
  chartConfig: {
    startIndex: 10,
    endIndex: null,
    timerId: 0,
    left: 10,
    right: 0,
  },
  chartData: [],
  status: loadingStatus.idle,
  filter: resolutions.amplo,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HistoryMonitorActions.TOGGLE_INDICATOR:
      return {
        ...state,
        indicators: action.payload,
      };
    case HistoryMonitorActions.GET_DATA:
      return {
        ...state,
        patientData: action.payload.patientData,
        metrics: action.payload.metrics,
        date: action.payload.date,
      };
    case HistoryMonitorActions.LOAD_CHART:
      return {
        ...state,
        chartData: action.payload.filteredData,
        chartConfig: {
          ...state.chartConfig,
          startIndex: action.payload.newStartIndex,
          endIndex: action.payload.newEndIndex,
        },
      };
    case HistoryMonitorActions.TOGGLE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case HistoryMonitorActions.CHANGE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}
