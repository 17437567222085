import { useEffect, useRef, useState } from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function parseStringToNumber(str) {
  return parseInt(str.match(/(\d+)/), 10);
}

// eslint-disable-next-line consistent-return
export function isEmpty(str) {
  if (typeof str === 'string') return str.trim() === '' || str === undefined || str === null;
}

export function InvalidEmail(email) {
  const expression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
  return !expression.test(email);
}

export function containsCharEspecial(str) {
  const invalids = [
    '@',
    '!',
    '#',
    '$',
    '%',
    '&',
    '*',
    ',',
    '<',
    '>',
    '/',
    '?',
    '(',
    ')',
    '{',
    '}',
    ';',
    '[',
    ']',
    '|',
    '+',
    '=',
    '-',
  ];

  for (let i = 0; i < invalids.length; i += 1) {
    if (str.includes(invalids[i])) {
      return true;
    }
  }
  return false;
}

export function InvalidName(name = '') {
  const invalids = [
    '@',
    '!',
    '#',
    '$',
    '%',
    '&',
    '*',
    ',',
    '<',
    '>',
    '/',
    '?',
    '(',
    ')',
    '{',
    '}',
    ';',
    '[',
    ']',
    '|',
    '+',
    '=',
    '-',
  ];

  if (name.length < 2) {
    return true;
  }

  for (let i = 0; i < invalids.length; i += 1) {
    if (name.includes(invalids[i])) {
      return true;
    }
  }
  return false;
}

export function InvalidIdProfessional(id = '') {
  const invalids = [
    '@',
    '!',
    '#',
    '$',
    '%',
    '&',
    '*',
    ',',
    '<',
    '>',
    '/',
    '?',
    '(',
    ')',
    '{',
    '}',
    ';',
    '[',
    ']',
    '|',
    '+',
    '=',
    "'",
    '"',
    '¨',
    ':',
    '°',
    'º',
    'ª',
    '`',
    '´',
    '~',
    '§',
    '¬',
    '¢',
    '£',
    '¹',
    '²',
    '³',
    '₢',
  ];

  const regex = /\s/g;

  for (let i = 0; i < invalids.length; i += 1) {
    if (id.includes(invalids[i])) {
      return true;
    }
  }
  if (regex.test(id)) {
    return true;
  }
  return false;
}

export function verifyPassWord(password) {
  if (!password) {
    throw new Error('a função requer o parâmetro password');
  }

  const regex = {
    size: /^(?=.{6,12}$).*/,
    haveUpperAndLowerLetters: /(?=.*[A-Z])(?=.*[a-z])/,
    haveNumbers: /^.*(?=.*[0-9])/,
  };

  const response = {
    isValid: true,
    invalidMessage: '',
  };

  if (!regex.size.test(password)) {
    response.isValid = false;
    response.invalidMessage = 'A senha deve conter entre 6 a 12 caracteres';
  }

  if (!regex.haveUpperAndLowerLetters.test(password)) {
    response.isValid = false;
    response.invalidMessage = 'A senha deve possuir letras maiúsculas e minúsculas';
  }

  if (!regex.haveNumbers.test(password)) {
    response.isValid = false;
    response.invalidMessage = 'A senha deve conter números';
  }

  return response;
}

export function rejectNumbersInString(string) {
  return string.replace(/([0-9])+/g, '');
}

export function rejectSpecialCharInString(str) {
  return str.replace(/[!@#$%¨&*()_+{}=/?:><<;|\\-]/g, '');
}

export function InvalidFullName(name = '') {
  if (InvalidName(name)) {
    return false;
  }
  const nameSplitSpace = name.trim().split(' ');
  if (nameSplitSpace.length > 1) {
    return false;
  }
  return true;
}

export function isNumeric(num) {
  return /^[0-9\s]*$/.test(num);
}

export function replaceIsNotNumeric(str) {
  return str.replace(/[^0-9.]+/g, '');
}

export function maskPhoneNumber(number) {
  const formattedNumber = number
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4}) (\d)(\d{4})/, '$1$2 $3')
    .replace(/(-\d{4})(\d+?$)/, '$1');

  return formattedNumber;
}

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?'
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))'
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
    + '(\\?[;&a-z\\d%_.~+=-]*)?'
    + '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
}

export function getActiveModulesForSickbed(sickbed) {
  const devices = sickbed?.devices;

  const hasMonitor = !!devices?.monitor?.id;
  const hasVentilator = !!devices?.['pulmonary-ventilator'].id;
  const hasPump = !!(devices?.['infusion-pump'].length > 0);

  return {
    hasMonitor,
    hasVentilator,
    hasPump,
  };
}

export function maskEmail(email) {
  if (!email) return '';

  return email.replace(/^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c);
}

export function useCheckVitalSignAge({ expireAfterSeconds, onExpiredCallFunction, vitalsigns }) {
  const [seconds, setSeconds] = useState(0);
  const oneSecond = 1000;

  useEffect(() => {
    setSeconds(0);
    const refresh = setInterval(() => setSeconds((prev) => prev + 1), oneSecond);
    return () => clearInterval(refresh);
  }, [vitalsigns]);

  const isVitalSignExpired = (seconds > expireAfterSeconds);

  if (isVitalSignExpired) onExpiredCallFunction();

  return {
    isVitalSignExpired,
    ageInSeconds: seconds,
  };
}
