export const actions = {
  TOGGLE_GO_BACK: 'ConfirmationModal/TOGGLE_GO_BACK',
  TOGGLE_LOG_OUT: 'ConfirmationModal/TOGGLE_LOG_OUT',
  TOGGLE_MODAL: 'ConfirmationModal/TOGGLE_MODAL',
  TOGGLE_PreventLeavingPage: 'ConfirmationPreventLeavingPage/TOGGLE_PreventLeavingPage',
  CHANGE_URL_TO: 'ConfirmationPreventLeavingPage/CHANGE_URL_TO',
  CHANGE_DESTINATION_COMPONENT: 'ConfirmationPreventLeavingPage/CHANGE_DESTINATION_COMPONENT',
};

export const toggleShouldGoBack = (payload) => ({
  type: actions.TOGGLE_GO_BACK,
  payload,
});

export const toggleShouldLogOut = (payload) => ({
  type: actions.TOGGLE_LOG_OUT,
  payload,
});

export const toggleConfirmationModal = (payload) => ({
  type: actions.TOGGLE_MODAL,
  payload,
});

export const togglePreventLeavingPage = (payload) => ({
  type: actions.TOGGLE_PreventLeavingPage,
  payload,
});

export const changeUrlTo = (payload) => ({
  type: actions.CHANGE_URL_TO,
  payload,
});

export const changeDestinationComponent = (payload) => ({
  type: actions.CHANGE_DESTINATION_COMPONENT,
  payload,
});
