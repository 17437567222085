import styled from 'styled-components';

export const ConnectionNotification = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 135px;
`;

export const HeadLine = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;
