import { options, languageActions } from './constants';

const INITIAL_STATE = options[0];

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case languageActions.CHANGE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}
