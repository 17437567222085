import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button, { models } from 'components/Button';
import Input from 'components/Input';

import { InvalidIdProfessional, maskEmail } from 'services/utils';
import { useTranslation } from 'react-i18next';

import {
  Form,
  Text,
  TextLink,
  Message,
} from '../styles';
import { setErrorForgotPassword, setForgotPasswordSent, fetchForgotPassword } from '../actions';

const ForgotPassword = ({ onClickForgotPassword }) => {
  const [form, setForm] = useState({ idProfissional: '' });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleSetNullMessage = useCallback(() => {
    dispatch(setErrorForgotPassword(null));
    dispatch(setForgotPasswordSent(null));
  }, [dispatch]);

  const returnToLoginPage = useCallback(() => {
    handleSetNullMessage();
    onClickForgotPassword(false);
  }, [onClickForgotPassword, handleSetNullMessage]);

  useEffect(() => {
    const fiveSeconds = 5000;
    if (auth.forgotPasswordSent != null) {
      setTimeout(returnToLoginPage, fiveSeconds);
    }
  }, [auth.forgotPasswordSent, returnToLoginPage]);

  function validateForm() {
    if (form.idProfissional.trim() === '') {
      dispatch(setErrorForgotPassword(t('text.typeProfessionalID')));
    } else if (InvalidIdProfessional(form.idProfissional.trim())) {
      dispatch(setErrorForgotPassword(t('text.typeValidProfessionalID')));
    } else {
      dispatch(fetchForgotPassword(form));
    }
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    handleSetNullMessage();
    validateForm();
  }

  return (
    <>
      <Text>{t('text.typeProfessionalIDToRecoverPassword')}</Text>
      <Form onSubmit={handleSubmitForm} autoComplete="off">
        <Input
          value={form.idProfissional}
          onChange={(e) => setForm({ ...form, idProfissional: e.target.value })}
          data-input-forgot-password={form.idProfissional}
          name="idProfissional"
          placeholder={t('professionalId')}
          width="100%"
        />
        <TextLink data-btn="forgot-password-back" onClick={returnToLoginPage}>{t('button.back')}</TextLink>
        {
          auth.errorForgotPassword && (
            <Message show={auth.errorForgotPassword} type="danger">
              {t(auth.errorForgotPassword)}
            </Message>
          )
        }
        {
          auth.forgotPasswordSent && (
            <Message show={auth.forgotPasswordSent}>
              {t(auth.forgotPasswordSent.text, { email: maskEmail(auth.forgotPasswordSent.email) })}
            </Message>
          )
        }
        <Button type="submit" text="ok" model={models.modelA} margin="0 auto" />
      </Form>
    </>
  );
};

export default ForgotPassword;
