/* eslint-disable consistent-return */
import dayjs from 'dayjs';
import api from 'services/api';
import { calculateFinishDate, calculateStartDate } from 'containers/Monitor/Dashboard/services';

// import payloadVitalSigns from "../mocks/infusionPump";
// import payloadVitalSigns from "../mocks/infusionPumpMulti";
// import payloadMedications from "../mocks/medications";

export const actions = {
  SET_MEDICATION_START: 'pump/SET_MEDICATION_START',
  SET_MEDICATION_DONE: 'pump/SET_MEDICATION_DONE',
  SET_MEDICATION_ERROR: 'pump/SET_MEDICATION_ERROR',
  GET_MEDICATIONS_DONE: 'pump/GET_MEDICATIONS_DONE',
  GET_MEDICATIONS_ERROR: 'pump/GET_MEDICATIONS_ERROR',
  GET_EQUIPMENTS_START: 'pump/GET_EQUIPMENTS_START',
  GET_EQUIPMENTS_DONE: 'pump/GET_EQUIPMENTS_DONE',
  GET_EQUIPMENTS_ERROR: 'pump/GET_EQUIPMENTS_ERROR',
};

export const getVitalSigns = (patientId, refresh = false) => async (dispatch) => {
  if (patientId === null) return;
  if (refresh) dispatch({ type: actions.GET_EQUIPMENTS_START });
  const equipments = {};
  let payloadVitalSigns;
  try {
    const itemsQuantity = 31;
    const start = calculateStartDate();
    const finished = calculateFinishDate();
    const url = `/api/v2/infusion-pump/group/${patientId}?startedAt=${start}&finishedAt=${finished}&itemsQuantity=${itemsQuantity}`;
    const res = await api.get(url);
    payloadVitalSigns = res?.data?.data ?? [];
  } catch (err) {
    return dispatch({ type: actions.GET_EQUIPMENTS_ERROR });
  }
  payloadVitalSigns.reverse().forEach(({ devices, messageDateTime }) => {
    const date = dayjs(messageDateTime).format('DD/MM/YYYY');
    const time = dayjs(messageDateTime).format('HH:mm:ss');
    const tick = dayjs(messageDateTime).format('HH:mm');
    devices.forEach(({ equipments: equips }) => {
      equips.forEach(({ id, vitalSigns }) => {
        vitalSigns.forEach((vs) => {
          const {
            typeResult, value, units, lacking,
          } = vs;
          if (!equipments[id]) equipments[id] = { id, chart: [] };
          if (typeResult === 'BASAL_RATE') equipments[id].info = { value, units };
          if (typeResult === 'MEDICATION') equipments[id].medication = { value, lacking };
          if (typeResult === 'BASAL_VOLUME_INFUSED') {
            equipments[id].chart.push({
              ...vs, date, time, tick,
            });
          }
        });
      });
    });
  });
  // getting custom medication for lacking ones
  // eslint-disable-next-line no-restricted-syntax
  for (const equipment of Object.values(equipments)) {
    if (equipment.medication.lacking) {
      try {
        const base = '/api/v2/custom-medication-infusion-pump';
        const url = `${base}?query={"patient._id":"${patientId}","equipment.id":"${equipment.id}"}&limit=1`;
        // eslint-disable-next-line no-await-in-loop
        const res = await api.get(url);
        const data = res?.data?.data;
        equipments[equipment.id].medication.value = data?.medication?.name ?? 'Substância não identificada';
      } catch (err) {
        return dispatch({ type: actions.GET_EQUIPMENTS_ERROR });
      }
    }
  }
  dispatch({ type: actions.SET_MEDICATION_DONE }); // we could be called from setMedication()
  dispatch({ type: actions.GET_EQUIPMENTS_DONE, equipments });
};

export const setMedication = (patientId, equipmentId, medicationId) => async (dispatch) => {
  dispatch({ type: actions.SET_MEDICATION_START });
  try {
    const base = '/api/v2/custom-medication-infusion-pump';
    const url = `${base}?query={"patient._id":"${patientId}","equipment.id":"${equipmentId}"}&limit=1&fields=_id`;
    const res = await api.get(url);
    const id = res?.data?.data?._id;
    const put = { equipment: { id: equipmentId }, medication: { _id: medicationId } };
    try {
      if (id) await api.put(`${base}/${id}`, put);
      else await api.post(`${base}`, { ...put, patient: { _id: patientId } });
    } catch (err) {
      return dispatch({ type: actions.SET_MEDICATION_ERROR });
    }
  } catch (err) {
    return dispatch({ type: actions.SET_MEDICATION_ERROR });
  }
  dispatch(getVitalSigns(patientId, true)); // SET_MEDICATION_DONE is dispatched there
};

export const getMedications = () => async (dispatch) => {
  try {
    const res = await api.get('/api/v2/medication');
    const payloadMedications = res?.data?.data ?? [];
    const medications = payloadMedications.sort(({ name: a }, { name: b }) => a.localeCompare(b));
    dispatch({ type: actions.GET_MEDICATIONS_DONE, medications });
  } catch (err) {
    return dispatch({ type: actions.GET_MEDICATIONS_ERROR });
  }
};
