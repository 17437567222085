import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function TableWrapper({ children, height }) {
  return (
    <Container height={height}>
      {children}
    </Container>
  );
}

TableWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  height: PropTypes.string,
};

TableWrapper.defaultProps = {
  height: '100%',
};
