import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { resources } from './languages';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    resources,
    defaultNS: ['translation'],
    lng: 'pt',
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,
    },
  });

export { i18n };
