import styled from 'styled-components';
import { breakpoints } from 'config/styles';

export const Container = styled.div`
  height: ${({ height }) => height};
  overflow: auto;
  transform: translateX(-30px);
  width: 100vw;
  
  @media (min-width: ${breakpoints.md}) {
    transform: unset;
    width: auto;
  }
`;
