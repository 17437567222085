import styled from 'styled-components';
import { zIndex } from 'config/styles';

export const IconAlert = styled.div`
  width: 50px;
`;
export const BlockMesage = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 90%;
  max-width: 350px;
  min-height: 120px;
  position: fixed;
  z-index: ${zIndex.lv4};
  right: ${({ show }) => (show ? '15px' : '-200%')};
  top: 30px;
  padding: 30px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  transition: right 0.8s;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondary.w350};
  display: flex;
  align-items: center;
`;

export const Headline = styled.h2`
  color: ${({ theme }) => theme.colors.text.w000};
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const Text = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.w000};
`;

export const Close = styled.button`
  color: ${({ type }) => (type === 'warning' || type === 'success' ? '#000' : '#fff')};
  font-size: 18px;
  position: absolute;
  right: 12px;
  top: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
`;
