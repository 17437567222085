import {
  combineReducers, createStore, applyMiddleware,
} from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import rootReducer from 'config/reducer';
import bedsReducer from 'containers/Beds/reducer';
import bedReducer from 'pages/Bed/reducer';
import dashboardReducer from 'containers/Monitor/Dashboard/reducer';
import validateVitalSignsReducer from 'containers/ValidateVitalSigns/reducer';
import HistoryMonitorReducer from 'containers/Monitor/History/reducer';
import loginReducer from 'pages/Login/reducer';
import userReducer from 'pages/Users/reducer';
import ParamsERPReducer from 'containers/ParamsSendERP/reducer';
import AlarmLevelReducer from 'containers/AlarmLevel/reducer';
import AlarmLevelConfigReducer from 'pages/AlarmConfiguration/reducer';
import userLogsReducer from 'pages/UserLogs/reducer';
import ventilatorReducer from 'containers/Air/Dashboard/redux/reducer';
import pumpReducer from 'containers/InfusionPump/Dashboard/redux/reducer';
import NotifyReducer from 'components/Notification/reducer';
import connectionReducer from 'components/ConnectionFailure/reducer';
import adminReducer from 'pages/Administrator/reducer';
import themeReducer from 'config/themes/reducer';
import modulesReducer from 'components/Module/reducer';
import languageReducer from 'components/SelectLanguage/reducer';
import ConfirmationReducer from 'components/ConfirmationModal/redux/reducer';

// genaral components
import sidebarOffCanvasReducer from 'components/SidebarOffCanvas/reducer';

const reducers = combineReducers({
  bed: bedReducer,
  beds: bedsReducer,
  validateVitalSigns: validateVitalSignsReducer,
  dashboard: dashboardReducer,
  HistoryMonitor: HistoryMonitorReducer,
  root: rootReducer,
  auth: loginReducer,
  user: userReducer,
  paramsERP: ParamsERPReducer,
  alarmLevel: AlarmLevelReducer,
  alarmLevelConfiguration: AlarmLevelConfigReducer,
  userLogs: userLogsReducer,
  ventilator: ventilatorReducer,
  pump: pumpReducer,
  sidebarOffCanvasReducer,
  notify: NotifyReducer,
  connection: connectionReducer,
  admin: adminReducer,
  theme: themeReducer,
  modules: modulesReducer,
  translate: languageReducer,
  confirmation: ConfirmationReducer,
});

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
);
export default store;
