import api from 'services/api';
import { setNotification } from 'components/Notification/actions';
import { configActions } from './constants';

export const refreshToken = () => async (dispatch) => {
  try {
    await api.get('/api/v2/refresh-token');
  } catch (err) {
    dispatch(
      setNotification({
        type: 'danger',
        headline: 'Falha no servidor',
        message: 'feedback.whenTraingToUpdateSessionTime',
      }),
    );
  }
};

export const getLogo = (user) => async (dispatch) => {
  const { urlLogo } = window.localStorage;
  if (user) {
    try {
      const hospital = user && user.hospital;
      await api.get(`/api/v2/hospital/${hospital._id}/logo/`)
        .then((response) => {
          if (response.data) {
            window.localStorage.setItem('urlLogo', response.data);
            dispatch({
              type: configActions.SET_URL_LOGO,
              payload: response.data,
            });
          }
        });
    // eslint-disable-next-line no-empty
    } catch (err) {}
  } else {
    dispatch({
      type: configActions.SET_URL_LOGO,
      payload: urlLogo,
    });
  }
};
