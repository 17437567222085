import { notifyActions } from 'config/constants';
import store from 'config/store';

export const setNotification = (config) => (dispatch) => {
  if (!config.fixed) {
    const { timeout } = store.getState().notify.config;
    if (timeout) clearInterval(timeout);
    // eslint-disable-next-line no-param-reassign
    config.timeout = setTimeout(
      () => {
        dispatch({ type: notifyActions.CHANGE_SHOW, payload: false });
      },
      config.timer ? config.timer : 4000,
    );
  }
  dispatch({ type: notifyActions.SHOW, payload: config });
  dispatch({ type: notifyActions.CHANGE_SHOW, payload: true });
};
