import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Logout from 'components/Logout';
import Icon, { names } from 'components/Icon';

import { changeIcu } from 'containers/Beds/actions';
import {
  Footer, FooterRight, FooterIcon, FooterGrid,
} from 'partials/Footer/styles';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as IconUserLogs } from '__assets/icons/user-logs.svg';
import { ReactComponent as IconAdmin } from '__assets/icons/icon-admin.svg';

import VersionNumber from '../VersionNumber';

const FooterDefault = (props) => {
  const { permissions } = props;
  const [toggleLogout, setToggleLogout] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Footer>
        <Logout open={toggleLogout} onClose={() => setToggleLogout(false)} />
        <FooterGrid>
          <div>
            <div style={{ display: 'flex' }}>
              <Link
                data-footer-btn-beds
                to="/"
                title={t('bed_plural')}
              >
                <FooterIcon>
                  <Icon name={names.home} width="40px" alt="Home" />
                </FooterIcon>
              </Link>
              {permissions.users_get ? (
                <Link
                  data-footer-bnt-user
                  to="/users"
                  title={t('user_plural')}
                >
                  <FooterIcon style={{ marginTop: '4px' }}>
                    <Icon name={names.user} width="30px" height="30px" alt="Usuários" />
                  </FooterIcon>
                </Link>
              ) : (
                ''
              )}

              {permissions['user-logs_get'] ? (
                <Link
                  data-footer-bnt-userlog
                  to="/user-logs"
                  title={t('userLogs')}
                >
                  <FooterIcon style={{ marginTop: '4px' }}>
                    <IconUserLogs width="32" height="32" />
                  </FooterIcon>
                </Link>
              ) : (
                ''
              )}

              {permissions.hospital_update ? (
                <Link to="/admin/configure" title="Administrar hospital">
                  <FooterIcon style={{ marginTop: '5px' }}>
                    <IconAdmin width="30" height="30" />
                  </FooterIcon>
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
          <div />
          <div>
            <FooterRight>
              <Icon name={names.orchestra1} width="215px" alt="Orchestra" margin="0 5px 0 0" />
              <VersionNumber />
              <button
                type="button"
                data-logout="bnt"
                style={{
                  background: 'transparent',
                  border: 0,
                  cursor: 'pointer',
                }}
                onClick={() => setToggleLogout(true)}
                title={t('button.logOut')}
              >
                <Icon name={names.logout} width="40px" alt="Sair" />
              </button>
            </FooterRight>
          </div>
        </FooterGrid>
      </Footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  icus: state.beds.icus,
  icuCurrent: state.beds.icuCurrent,
  user: state.root.user,
  permissions: state.root.permissions,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ changeIcu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FooterDefault);
