const INITIAL_STATE = {
  urlBlank: true,
  configure: {
    expiration: '',
    url: '',
    name: '',
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'admin/TOGGLE_URL_BLANK':
      return { ...state, urlBlank: action.payload };
    case 'admin/SET_CONFIGURE':
      return { ...state, configure: action.payload };
    case 'admin/SET_URL':
      return {
        ...state,
        configure: { ...state.configure, url: action.payload },
      };
    default:
      return state;
  }
}
