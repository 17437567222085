import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  margin: 0 auto;
  place-content: center;
`;

export const Buttons = styled.div`
  display: flex;

  button {
    margin-right: 10px;
  }
`;

export const Span = styled.div`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: 18px;
  margin-bottom: 50px;
`;
