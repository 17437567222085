import dayjs from 'dayjs';

export function calculateStartDate() {
  return dayjs().subtract(30, 'm').toJSON();
}

export function calculateFinishDate() {
  return dayjs().toJSON();
}

export function formatToHourAndMinutes(dateTime) {
  return dayjs(dateTime).format('HH:mm');
}
