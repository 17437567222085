/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { setNotification } from 'components/Notification/actions';
import TableWrapper from 'components/TableWrapper';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'config/styles';
import { ReactComponent as IconUserAdd } from '__assets/icons/user-add.svg';
import { ReactComponent as IconUserEdit } from '__assets/icons/user-edit.svg';
import { ReactComponent as IconUserLog } from '__assets/icons/user-log.svg';
import { ReactComponent as IconTrash } from '__assets/icons/trash.svg';
import Footer from 'partials/Footer/Default';
import { usersActions } from 'config/constants';
import SiteContainer from 'components/SiteContainer';
import ModalRemoveUser from './ModalRemoveUser';
import Modal from './Modal';
import { Btn, BtnAdd } from './styles';
import {
  getUsers,
  createUser,
  changedUser,
  removeUser,
  getCategoriesUsers,
  setUserSelected,
} from './actions';

const userDataNull = {
  _id: '',
  name: '',
  idProfissional: '',
  erpCode: '',
  companyFunction: '',
  authorization: {
    _id: null,
  },
  phoneNumber: '',
  email: '',
  password: '',
  passwordConfirmation: '',
};

function compare(a, b) {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return collator.compare(a.name, b.name);
}

function Users({
  users,
  user,
  userCurrent,
  permissions,
  categoriesUsers,
  getCategoriesUsers,
  statusSavingUser,
  getUsers,
  createUser,
  changedUser,
  setUserSelected,
  setNotification,
}) {
  const [userToRemove, setUserToRemove] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [formData, setFormData] = useState(userDataNull);
  const [mode, setMode] = useState('create');
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalRemoveUser, setToggleModalRemoveUser] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const hospitalId = user && user.hospital && user.hospital._id;

  useEffect(() => {
    getUsers();
    getCategoriesUsers();
  }, [getCategoriesUsers, getUsers]);

  useEffect(() => {
    const usersSortByDate = users.sort(compare);

    setUsersList(usersSortByDate);
  }, [users]);

  function editUser(user) {
    const payload = {
      _id: user._id,
      name: !user.name ? '' : user.name,
      idProfissional: !user.idProfissional ? '' : user.idProfissional,
      erpCode: !user.erpCode ? '' : user.erpCode,
      email: !user.email ? '' : user.email,
      authorization: {
        _id: user.authorization._id,
      },
      hospital: {
        _id: hospitalId,
      },
      phoneNumber: !user.phoneNumber ? '' : user.phoneNumber,
      companyFunction: !user.companyFunction ? '' : user.companyFunction,
      password: '',
      passwordConfirmation: '',
    };
    setUserSelected(payload);
    setFormData(payload);
    setToggleModal(true);
    setMode('edit');
  }

  function closeModal() {
    setMode('create');
    setFormData(userDataNull);
    setToggleModal(false);
  }

  function showModal() {
    setFormData(userDataNull);
    setToggleModal(true);
  }

  function handleClickOnRemoveUser(userID) {
    setToggleModalRemoveUser(!toggleModalRemoveUser);
    setUserToRemove(userID);
  }

  document.title = t('title.users');

  if (statusSavingUser === 'success') {
    setTimeout(() => {
      dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'idle' });
      closeModal();
    }, 100);
  }

  return (
    <>
      <Modal
        hospitalId={hospitalId}
        statusSavingUser={statusSavingUser}
        categoriesUsers={categoriesUsers}
        setNotification={setNotification}
        createUser={createUser}
        changedUser={changedUser}
        setFormData={setFormData}
        formData={formData}
        mode={mode}
        user={user}
        toggleModal={toggleModal}
        closeModal={closeModal}
      />
      {permissions.create_user ? (
        <BtnAdd onClick={showModal} data-cy="buttonAddUser">
          <IconUserAdd width="25" height="25" />
          {t('addUser')}
        </BtnAdd>
      ) : (
        ''
      )}

      <SiteContainer margin="20px auto 90px">
        <TableWrapper>
          <Table data-cy="usersList">
            <Thead>
              <tr>
                <Th>
                  {t('professionalId')}
                </Th>
                <Th>
                  {t('user')}
                </Th>
                <Th>
                  {t('role')}
                </Th>
                <Th>
                  {t('permissionLevel')}
                </Th>
                <Th>
                  {t('email')}
                </Th>
                <Th>
                  {t('phone')}
                </Th>
                <Th>
                  {t('codErp')}
                </Th>
                <Th
                  width="200px"
                  align="center"
                >
                  {t('action', { count: 2 })}
                </Th>
              </tr>
            </Thead>
            <Tbody>
              {usersList.length === 0 ? (
                <Tr>
                  <Td align="center" colSpan="8">
                    {t('text.noUserRegistred')}
                  </Td>
                </Tr>
              ) : (
                usersList.map((user) => {
                  const permission = categoriesUsers.length > 0
                    ? categoriesUsers.find(
                      (c) => c.value === user.authorization._id,
                    )
                    : '';
                  return (
                    <Tr key={user._id} data-cy="user">
                      <Td>{user.idProfissional}</Td>
                      <Td>{user.name}</Td>
                      <Td>{user.companyFunction}</Td>
                      <Td>{user.authorization.displayName}</Td>
                      <Td>{user.email}</Td>
                      <Td>{user.phoneNumber}</Td>
                      <Td>{user.erpCode}</Td>
                      <Td
                        align="center"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Btn
                          style={{ display: 'none' }}
                          title={`Logs do ${user.name}`}
                        >
                          <IconUserLog width="25" height="25" />
                        </Btn>
                        {user.authorization.name === 'admin'
                          || user.authorization.name === 'user-tv' ? (
                            false
                          ) : !userCurrent.isAdmin
                          && permission
                          && permission.label === 'Administrador' ? (
                              '- '
                            ) : permissions.change_user ? (
                              <Btn
                                onClick={() => editUser(user)}
                                title={`Editar ${user.name}`}
                              >
                                <IconUserEdit width="25" height="25" />
                                <span>{t('edit')}</span>
                              </Btn>
                            ) : (
                              ''
                            )}

                        {user.authorization.name === 'admin'
                          || user.authorization.name === 'user-tv' ? (
                            false
                          ) : userCurrent._id === user._id ? (
                            false
                          ) : !userCurrent.isAdmin
                          && permission
                          && permission.label === 'Administrador' ? (
                              '-'
                            ) : permissions.delete_user ? (
                              <Btn onClick={() => handleClickOnRemoveUser(user._id)} title={`Remover ${user.name}`}>
                                <IconTrash width="25" height="25" />
                                <span>{t('delete')}</span>
                              </Btn>
                            ) : (
                              ''
                            )}
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </SiteContainer>
      <ModalRemoveUser
        userToRemove={userToRemove}
        toggleModalRemoveUser={toggleModalRemoveUser}
        setToggleModalRemoveUser={setToggleModalRemoveUser}
      />
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  users: state.user.data,
  user: state.root.user,
  categoriesUsers: state.user.categories,
  userCurrent: state.root.user,
  permissions: state.root.permissions,
  statusSavingUser: state.user.statusSavingUser,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUsers,
    createUser,
    changedUser,
    removeUser,
    getCategoriesUsers,
    setUserSelected,
    setNotification,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Users);
