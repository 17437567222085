import { actions } from './actions';

const init = {
  vitalSigns: {},
  loading: true,
  vitalSignsShow: {},
};

export default function reducer(state = init, { type, vitalSigns, vitalSignsShow }) {
  switch (type) {
    case actions.SET_VITALSIGNSSHOW:
      return { ...state, vitalSignsShow, vitalSigns: { ...state.vitalSigns } };
    case actions.GET_VITALSIGNS_DONE:
      return { ...state, loading: false, vitalSigns };
    case actions.GET_VITALSIGNS_ERROR:
      return { ...state, loading: false, vitalSigns: init.vitalSigns };
    default:
      return state;
  }
}
