import styled from 'styled-components';
import { breakpoints } from 'config/styles';

export const Container = styled.div`
  margin: 10px 30px;

  @media (min-width: ${breakpoints.md}) {
    margin: 10px 50px;
  }
  `;

export const Absolute = styled.div`
  left: calc(50% - 45px);
  position: absolute;
  @media (min-width: ${breakpoints.md}) {
    left: calc(50% - 65px);
  }
`;

export const Btn = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  margin-right: 10px;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.w000};
  margin-right: 15px;
  font-size: 10px;
  > span {
    font-family: 'Abel', sans-serif;
    margin-left: 5px;
    font-size: ${({ theme }) => theme.fontSize.xs};
    text-transform: lowercase;
  }
`;

export const BtnAdd = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: 20px;
  font-weight: bold;
  background: transparent;
  width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 0;
  margin: 58px auto 30px;
  outline: none;
  border: 0;
`;

export const FormControl = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.text.w000};
  margin-bottom: 10px;
  margin-left: 13px;
`;
