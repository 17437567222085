import { configActions } from './constants';

const INITIAL_STATE = {
  useErp: false,
  user: null,
  isAuth: false,
  icu: null,
  timer: 0,
  expiredSession: false,
  collectionTime: 60000,
  permissions: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case configActions.SET_USEERP:
      return { ...state, useErp: action.payload };
    case configActions.SET_USER_LOGIN:
      return { ...state, user: action.payload };
    case configActions.SET_AUTH:
      return { ...state, isAuth: true };
    case configActions.LOGOUT:
      return { ...state, user: null, isAuth: false };
    case configActions.SET_URL_LOGO:
      return { ...state, urlLogo: action.payload };
    case configActions.CHANGED_ICU:
      return { ...state, icu: action.payload };
    case configActions.SET_EXPERIED_SESSION:
      return { ...state, expiredSession: true };
    case configActions.RESET_TIMER:
      return { ...state, timer: 0 };
    case configActions.SET_TIMER:
      return { ...state, timer: state.timer + 1 };
    case configActions.SET_PERMISSIONS:
      return { ...state, permissions: action.payload };
    case configActions.SET_COLLECTION_TIME:
      return { ...state, collectionTime: parseInt(action.payload, 10) };
    case configActions.SET_EQUIPMENTS:
      return { ...state, equipments: action.payload };
    default:
      return state;
  }
}
