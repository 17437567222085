import React, { useState, useRef } from 'react';
import { useOutsideClick } from 'services/utils';

import { models } from './constants';

import {
  Container, ComboBox, Option, ItemSelected, Arrow, Placeholder, TextEllipsed,
} from './styles';

export default function Select({
  id,
  value = null,
  onChange = null,
  options = [],
  placeholder = '',
  position = 'bottom',
  styles = null,
  model = models.modelA,
  sort = true,
}) {
  const [open, setOpen] = useState(false);
  const selected = options.find((o) => o.value === value);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (open) {
      setOpen(false);
    }
  });

  function compare(a, b) {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return collator.compare(a.label, b.label);
  }

  if (sort) {
    options.sort(compare);
  }

  return (
    <Container ref={ref} onClick={() => setOpen(!open)} id={id}>
      <ItemSelected
        model={model}
        position={position}
        open={open}
        style={styles}
        data-select={selected ? selected.label : ` -- ${placeholder} --`}
        selected={selected}
      >
        <TextEllipsed>
          {
            options.length > 0
              ? selected?.label || <Placeholder>{placeholder}</Placeholder>
              : <Placeholder>{placeholder}</Placeholder>
          }
        </TextEllipsed>
      </ItemSelected>
      <ComboBox model={model} open={open} position={position} length={options.length}>
        {
          options.map((option) => (
            <Option
              model={model}
              key={Math.random()}
              onClick={() => onChange(option)}
              value={option.value}
              data-select-item={option.label}
            >
              {option.label}
            </Option>
          ))
        }
      </ComboBox>
      <Arrow open={open} model={model} position={position} />
    </Container>
  );
}

export { models };
