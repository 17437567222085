import React from 'react';
import { useTranslation } from 'react-i18next';
import { notifyActions } from 'config/constants';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as IconWarning } from '__assets/icons/icon-warning.svg';
import { ReactComponent as IconSucces } from '__assets/icons/icon-success.svg';
import { ReactComponent as IconDanger } from '__assets/icons/icon-danger.svg';
import {
  Container,
  Headline,
  Text,
  BlockMesage,
  IconAlert,
  Close,
} from './styles';

export default function Notification() {
  const { show, config } = useSelector((state) => state.notify);
  const { type, message } = config;
  const dispatch = useDispatch();

  const configHeadline = {
    success: 'success',
    warning: 'attention',
    danger: 'error',
  };

  const { t } = useTranslation();

  return (
    <Container show={show} type={type} data-cy="notification" data-cy-is-visible={show}>
      <IconAlert>
        {
          type === 'success' && <IconSucces width="30" height="30" />
        }
        {
          type === 'danger' && <IconDanger width="30" height="30" />
        }
        {
          type !== 'danger' && type !== 'success' && <IconWarning width="30" height="30" />
        }
      </IconAlert>
      <BlockMesage>
        <Headline>{t(configHeadline[type])}</Headline>
        <Text>{t(message)}</Text>
      </BlockMesage>

      <Close
        type={type}
        onClick={() => dispatch({ type: notifyActions.TOGGLE_SHOW })}
      >
        &times;
      </Close>
    </Container>
  );
}
