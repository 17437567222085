import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import Icon, { names } from 'components/Icon';

import { changeLanguage } from './actions';
import { options } from './constants';

import {
  Container, ActiveItem, Text, Options, Opition,
} from './styles';

export default function SelectLanguage({ mini }) {
  const dispatch = useDispatch();
  const { translate } = useSelector((state) => state);

  function handleClick(option) {
    window.localStorage.setItem('language', JSON.stringify(option));
    dispatch(changeLanguage(option));
  }

  return (
    <Container>
      <ActiveItem>
        <Icon height="24" width="24" name={names[translate?.flagName]} />
        <Text>{translate?.language}</Text>
      </ActiveItem>
      <Options mini={mini}>
        {
          options.map((option) => (
            <Opition key={option.id} onClick={() => handleClick(option)}>
              <Icon height="24" width="24" name={names[option.flagName]} />
              <Text>{mini ? option.language : option.languageName}</Text>
            </Opition>
          ))
        }
      </Options>
    </Container>
  );
}

SelectLanguage.defaultProps = {
  mini: false,
};

SelectLanguage.propTypes = {
  mini: PropTypes.string,
};
