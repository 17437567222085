import React, { useState, useEffect } from 'react';
import { ReactComponent as IconClose } from '__assets/icons/icon-close.svg';
import {
  Container, Header, Body, Close, Overlay,
} from './styles';

function Modal({
  children, open, closeModal, styleContainer, styleBody, noHeader,
}) {
  const [showHide, setShowHide] = useState(false);

  useEffect(() => {
    setShowHide(open);
  }, [open]);

  return (
    <>
      <Overlay show={showHide} data-modal-overlay="true" />
      <Container show={showHide} style={styleContainer} data-modal-container="true">
        {noHeader ? null : (
          <Header>
            {closeModal ? (
              <Close onClick={closeModal}>
                <IconClose width="15" height="15" fill="#000" />
              </Close>
            ) : null}
          </Header>
        )}
        <Body style={styleBody}>{children}</Body>
      </Container>
    </>
  );
}

export default Modal;
