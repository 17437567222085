import React, { useState } from 'react';
import { verifyPassWord } from 'services/utils';
import Footer from 'partials/Footer/Temporary';
import { useDispatch } from 'react-redux';

import { setNotification } from 'components/Notification/actions';
import Notification from 'components/Notification';
import Button, { models } from 'components/Button';
import Input from 'components/Input';
import SelectLanguage from 'components/SelectLanguage';
import Header from 'components/Header';

import { useTranslation } from 'react-i18next';
import { changePassword } from './actions';
import {
  Container,
  Headline,
  Text,
  Title,
  List,
  Form,
  Label,
} from './styles';

const userPasswordNull = {
  password: '',
  passwordConfirmation: '',
};

export default function FirstPasswordChange({ user }) {
  const [formData, setFormData] = useState(userPasswordNull);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  document.title = t('title.changePassword');

  const dispatchNotification = (data) => {
    dispatch(setNotification(data));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.passwordConfirmation) {
      dispatchNotification({
        type: 'warning',
        headline: 'ATENÇÂO',
        message: 'text.criteriaForCreatingPasswordDifferentPassword',
      });
    } else if (!verifyPassWord(formData.password).isValid) {
      dispatchNotification({
        type: 'warning',
        headline: 'ATENÇÂO',
        message: verifyPassWord(formData.password).invalidMessage,
      });
    } else {
      dispatch(
        changePassword(user._id, {
          ...formData,
          idProfissional: user.idProfissional,
          generatedPassword: false,
        }),
      );
    }
  };

  return (
    <>
      <Header>
        <SelectLanguage mini />
      </Header>
      <Container>
        <Notification />
        <Headline>
          <Title>
            {`${user.name}, `}
            {t('welcomeTo')}
            <b> Orchestra</b>
            .
          </Title>
          <Text>
            {t('text.firstAccessText')}
          </Text>
        </Headline>
        <List>
          <Text>
            <b>{t('text.criteriaForCreatingPassword')}</b>
          </Text>
          <ul>
            <li>{t('text.criteriaForCreatingPasswordNumberOfChars')}</li>
            <li>{t('text.criteriaForCreatingPasswordTypesOfChars')}</li>
          </ul>
        </List>
        <Form autoComplete="off">
          <Label>
            {t('newPassword')}
          </Label>
          <Input
            width="100%"
            value={formData.password}
            autoComplete="new-password"
            maxLength="12"
            required
            type="password"
            name="passwordOne"
            placeholder={t('text.typeYourPassword')}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <Label>
            {t('passwordConfirmation')}
          </Label>
          <Input
            width="100%"
            value={formData.passwordConfirmation}
            autoComplete="new-password"
            maxLength="12"
            required
            type="password"
            name="passwordTwo"
            placeholder={t('text.typeYourPasswordConfirmation')}
            onChange={(e) => {
              setFormData({ ...formData, passwordConfirmation: e.target.value });
            }}
          />
          <Button onClick={handleSubmit} text={t('createPassword')} model={models.modelB} margin="26px auto" />
        </Form>
        <Footer />
      </Container>
    </>
  );
}
