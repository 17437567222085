import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 18px;
  justify-content: center;
  margin-bottom: 5px;

  & > img {
    cursor: pointer;
  }
`;
