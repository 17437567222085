import styled, { css } from 'styled-components';
import store from './store';

const { theme } = store.getState();

export const breakpoints = {
  xs: '600px',
  sm: '768px',
  md: '1200px',
  lg: '1600px',
  xl: '1920px',
};

export const zIndex = {
  lv1: 1,
  lv2: 2,
  lv3: 3,
  lv4: 4,
  lvMax: 5,
};

export const Top = styled.div`
  margin-top: 200px;
`;

export const Container = styled.div`
  max-width: 100%;
  margin-top: 190px;
  padding: 0 50px;

  @media (min-width: 1024px) {
    margin-top: 100px;
  }
`;

export const Row = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 320px) {
    ${(props) => (props['justify-xs']
    ? `
        justify-content: ${props['justify-xs']}
      `
    : '')}
  }

  @media (min-width: ${breakpoints.sm}) {
    ${(props) => (props['justify-sm']
    ? `
        justify-content: ${props['justify-sm']}
      `
    : '')}
  }

  @media (min-width: ${breakpoints.md}) {
    ${(props) => (props['justify-xs']
    ? `
        justify-content: ${props['justify-xs']}
      `
    : '')}
  }

  @media (min-width: ${breakpoints.lg}) {
    ${(props) => (props['justify-lg']
    ? `
        justify-content: ${props['justify-lg']}
      `
    : '')}
  }
  @media (min-width: ${breakpoints.xl}) {
    ${(props) => (props['justify-xl']
    ? `
        justify-content: ${props['justify-xl']}
      `
    : '')}
  }
  ${(props) => (props.justify ? `justify-content: ${props.justify}` : '')}
`;

export const Col = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 8px;
  padding-left: 8px;
  flex-basis: 100%;
  max-width: 100%;

  @media (min-width: 320px) {
    ${(props) => (props.xs
    ? `
      flex-basis: ${props.xs}%;
      max-width: ${props.xs}%;
    `
    : '')}
  }

  @media (min-width: ${breakpoints.sm}) {
    ${(props) => (props.sm
    ? `
      flex-basis: ${props.sm}%;
      max-width: ${props.sm}%;
    `
    : '')}
  }
  @media (min-width: ${breakpoints.md}) {
    ${(props) => (props.md
    ? `
      flex-basis: ${props.md}%;
      max-width: ${props.md}%;
    `
    : '')}
  }
  @media (min-width: ${breakpoints.lg}) {
    ${(props) => (props.lg
    ? `
      flex-basis: ${props.lg}%;
      max-width: ${props.lg}%;
    `
    : '')}
  }
  @media (min-width: ${breakpoints.xl}) {
    ${(props) => (props.xl
    ? `
      flex-basis: ${props.xl}%;
      max-width: ${props.xl}%;
    `
    : '')}
  }
`;

export const Table = styled.table`
  min-width: 1100px;
  width: 100%;
  height: auto;
`;

export const Thead = styled.thead`
  position: ${({ keepHeader }) => (keepHeader ? 'sticky' : 'unset')};
  top: 0;
  width: 100%;
  height: 50px;
  background-color: ${theme.colors.secondary.w500};
  z-index: ${zIndex.lv1};
  th {
    height: 50px;
  }
`;

export const Tbody = styled.tbody`
  width: 100%;
  td {
    height: 56px;
  }
`;

export const Th = styled.th`
  font-weight: 400;
  text-transform: uppercase;
  height: 30px;
  font-size: ${({ size }) => (size || '14px')};
  color: ${({ textColor }) => (textColor || theme.colors.text.w000)};
  background: ${({ bgColor }) => (bgColor || 'transparent')};
  padding-left: 10px;
  ${(props) => (props.align
    ? css`
          text-align: ${props.align};
        `
    : css`
          text-align: left;
        `)};
`;

export const Tr = styled.tr`
  &:nth-of-type(even) {
    background-color: ${({ colorEven }) => (colorEven || theme.colors.secondary.w300)};
  }
  &:nth-of-type(odd) {
    background-color: ${({ colorOdd }) => (colorOdd || theme.colors.secondary.w100)};
  }
  ${(props) => (props.active === true
    ? css`
          background-color: ${theme.colors.primary.w200} !important;
          &:hover {
            background-color: ${theme.colors.primary.w200} !important;
          }
        `
    : css`
          background-color: transparent;
        `)}
`;

export const Td = styled.td`
  height: 30px;
  color: ${theme.colors.text.w000};
  padding-left: 10px;
  ${(props) => (props.align
    ? css`
          text-align: ${props.align};
        `
    : css`
          text-align: left;
        `)};
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.text.w000};
  font-family: 'Abel', sans-serif;
  text-align: ${({ textAlign }) => (textAlign || 'left')};
  font-size: ${({ fontSize }) => (fontSize || 'unset')};
`;
