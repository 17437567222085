import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { socket } from 'services/socket';
import easterEgg from 'services/easterEgg';

import Loading from 'components/Loading';
import * as bedsActions from './actions';
import Bed from './Bed';
import { BedsList } from './styles';

function compare(a, b) {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return collator.compare(a.name, b.name);
}

function Beds({
  loadBeds,
  onDispatchSocket,
  loadIcus,
  data,
  icuCurrent,
  addDataToDataHistory,
  saveLastDataVitalSigns,
  permissions,
  loading,
}) {
  const [bedItem, setBedItem] = useState([]);
  const [showDebugData, setShowDebugData] = useState(false);
  const { expiredSession } = useSelector((state) => state.root);

  useEffect(() => {
    easterEgg('showmethecode', () => setShowDebugData(true));
  }, []);

  useEffect(() => {
    const { dataHistory } = window.localStorage;
    if (dataHistory) {
      addDataToDataHistory();
    } else {
      loadBeds();
    }

    loadIcus();
    return () => {
      saveLastDataVitalSigns();
    };
  }, [addDataToDataHistory, loadBeds, loadIcus, saveLastDataVitalSigns]);

  useEffect(() => onDispatchSocket(), [onDispatchSocket]);
  useEffect(() => {
    if (expiredSession) socket.disconnect();
  }, [expiredSession]);

  useEffect(() => {
    const dataFilter = data && data
      .filter((d) => d.icu === (icuCurrent && icuCurrent.value))
      .sort(compare);

    setBedItem(dataFilter);
  }, [data, icuCurrent]);

  const { t } = useTranslation();

  document.title = t('title.home');

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading type="loading" title="Carregando leitos..." />
      </div>
    );
  }
  return (
    <BedsList data-page-beds>
      {bedItem && bedItem.length === 0 ? (
        <div
          style={{
            width: '300px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: 'calc(50% - 150px)',
            alignItems: 'center',
          }}
        >
          <Loading
            type="fail"
            title={`Nenhum leito a exibir para ALA ${icuCurrent && icuCurrent.label}`}
          />
        </div>
      ) : (
        bedItem.map((d) => {
          const key = d._id + d.name;
          return (
            <Bed key={key} data={d} permissions={permissions} showDebugData={showDebugData} />
          );
        })
      )}
    </BedsList>
  );
}

const mapStateToProps = (state) => ({
  data: state.beds.data,
  loading: state.beds.loading,
  icuCurrent: state.root.icu,
  user: state.root.user,
  permissions: state.root.permissions,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(bedsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Beds);
