import { paramsSendERPActions } from 'config/constants';

const INITIAL_STATE = {
  data: [],
  message: null,
  loading: true,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case paramsSendERPActions.LOAD_DATA:
      return {
        ...state, data: action.payload, message: null, loading: false,
      };
    case paramsSendERPActions.MESSAGE:
      return { ...state, message: action.payload };
    case paramsSendERPActions.LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
