/* eslint-disable no-param-reassign */
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import api from 'services/api';
import { bedsActions, configActions, dashboardActions } from 'config/constants';
import { setNotification } from 'components/Notification/actions';
import store from 'config/store';
import {
  // socketMonitor,
  // socketSickBed,
  socket,
} from 'services/socket';
import { getEquipments, validateSickBedsData } from './services';

/*
  observableBeds()
  @description: Observes whether the patient is doing well or badly
  @params: d
  @response: null
*/

export const observableBeds = (bed) => (dispatch) => {
  const { patient } = bed;
  const { data } = store.getState().beds;
  const { icu } = store.getState().root;

  const updateBeds = data && data.map((d) => {
    if (
      patient.sickbed && d._id === patient.sickbed._id
      && patient.sickbed.icu._id === (icu && icu.value)
    ) {
      d.status = patient.sickbed.status;
      d.pid = patient.pid;
      d.initials = patient.name;
      d.fullName = patient.fullName;
      d.patientId = patient._id ? patient._id : '';
    }
    return d;
  });

  dispatch({ type: bedsActions.LOAD_BEDS, payload: updateBeds });
};

/*
  setVitalSignToBed()
  @description: Send vital signs to bed
  @params: d, r and vi
  @response: null
*/

export const setVitalSignToBed = (vital) => (dispatch) => {
  const { data } = store.getState().beds;
  const updateBedsToVitalSigns = data && data.map((d) => {
    if (d._id === vital?.patient?.sickbed?._id) {
      d.vitalsigns = [];

      // d.hasVitalSignToErp = vital.vitalSign.hasVitalSignToErp;

      vital.devices[0].equipments[0].vitalSigns.forEach((r) => {
        // vital.vitalSign.results.forEach((r) => {
        if (d.vitalsigns.map((vi) => vi.type).indexOf(r.typeResult) === -1) {
          d.vitalsigns.push({
            status: r.status && r.status.name ? r.status.name : 'normal',
            value: r.value,
            scale: r.units,
            type: r.typeResult,
            lacking: r.lacking,
          });
        }
      });
    }
    return d;
  });

  dispatch({ type: bedsActions.LOAD_BEDS, payload: updateBedsToVitalSigns });
};

/*
  onDispatchSocket()
  @description: Dispatch and show bed data
  @params: data
  @response: null
*/

export const onDispatchSocket = () => (dispatch) => {
  const hospitalId = process.env.REACT_APP_HOSPITALID;
  if (hospitalId) {
    // socketMonitor.connect();
    // socketSickBed.connect();
    socket.connect(); // recebe todos os namespaces.
    socket.on('sickbed', (data) => {
      dispatch(observableBeds(data));
    });
    socket.on('monitor', (data) => {
      dispatch(setVitalSignToBed(data));
    });
  }
};

/*
  loadBeds()
  @description: Load bed data
  @params: res and d
  @response: null
*/

export const loadBeds = () => async (dispatch) => {
  const response = await api.get('/api/v2/sickbed');
  const { data, icuSickbeds } = validateSickBedsData(response);

  dispatch({ type: bedsActions.LOADING });
  dispatch({ type: bedsActions.LOAD_BEDS, payload: data });
  dispatch({ type: 'beds/ICUS_BEDS', payload: values(groupBy(icuSickbeds, 'icuId')) });
};

/*
  loadBeds()
  @description: Load bed data
  @params: res and d
  @response: null
*/

export const loadBedsAdministrator = () => async (dispatch) => {
  await api.get('/api/v2/sickbed').then((res) => {
    const icuSickbeds = [];

    if (res.data.data.length > 0) {
      res.data.data.forEach((d) => {
        icuSickbeds.push({
          _id: d._id,
          collectionTime: d.collectionTime,
          name: d.name,
          status: !d.status ? '' : d.status,
          icuId: d.icu._id,
          icuName: d.icu.name,
          devices: d.devices,
          equipments: getEquipments(d.devices),
        });
      });
    }
    dispatch(onDispatchSocket());
    dispatch({ type: bedsActions.LOADING });
    dispatch({ type: bedsActions.ICUS_BEDS, payload: values(groupBy(icuSickbeds, 'icuId')) });
  });
};
/*
  addDataToDataHistory()
  @description: Add beds data
  @params: null
  @response: null
*/

export const addDataToDataHistory = () => (dispatch) => {
  const dataHistory = JSON.parse(window.localStorage.getItem('dataHistory'));
  dispatch({ type: bedsActions.LOAD_BEDS, payload: dataHistory || [] });
  dispatch(loadBeds());
  setTimeout(() => {
    window.localStorage.removeItem('dataHistory');
  }, 300);
};

/*
  saveLastDataVitalSigns()
  @description: Save the last bed information
  @params: data
  @response: null
*/

export const saveLastDataVitalSigns = () => (dispatch) => {
  const { data } = store.getState().beds;
  dispatch({ type: dashboardActions.TOGGLE_LOADING, payload: true });
  window.localStorage.setItem('dataHistory', JSON.stringify(data));
  socket.disconnect();
  socket.disconnect();
};

/*
  loadIcus()
  @description: Send bed data
  @params: err
  @response: null
*/

export const loadIcus = () => async (dispatch) => {
  try {
    const request = await api.get('/api/v2/icu');
    const { data } = request.data;

    dispatch({ type: bedsActions.LOAD_ICUS, payload: data });
  // eslint-disable-next-line no-empty
  } catch (err) { }
};

/*
  changeIcu()
  @description: Change icu status
  @params: null
  @response: null
*/

export const changeIcu = (icu, user) => async (dispatch) => {
  const localIcu = JSON.parse(window.localStorage.getItem('icu'));
  if (icu) {
    window.localStorage.removeItem('icu');
    window.localStorage.setItem('icu', JSON.stringify(icu));
  }
  dispatch({
    type: configActions.CHANGED_ICU,
    payload: icu,
  });
  if (localIcu && (localIcu.label !== icu.label)) {
    const token = window.localStorage.auth;
    const dataUserLog = {
      message: `Troca de UTI, de ${localIcu.label} para ${icu.label}`,
      user,
      token,
    };
    try {
      await api.post('/api/v2/users-logs', dataUserLog);
    } catch (error) {
      dispatch(
        setNotification({
          message: `Error Log de troca de UTI: ${error.message}`,
          headline: 'error',
          type: 'danger',
        }),
      );
    }
  }
};
