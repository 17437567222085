export const models = {
  modelA: 'circle',
  modelASecondary: 'circleSecondary',
  modelB: 'box',
  modelBSecondary: 'boxSecondary',
};

export const states = {
  default: 'default',
  disabled: 'disabled',
  loading: 'loading',
};
