import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Button, { models, states } from 'components/Button';
import Input from 'components/Input';
import { loginStatus } from 'pages/Login/constants';

import {
  Form,
  Text,
  TextLink,
  Message,
  WrapperTextLink,
} from '../styles';
import { fetchLogin, setError } from '../actions';

const LoginForm = ({ onClickForgotPassword }) => {
  const [form, setForm] = useState({ idProfissional: '', password: '' });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function validateForm() {
    if (form.idProfissional.trim() === '') {
      dispatch(setError(t('text.informID')));
      document.querySelector('#username').focus();
      return false;
    }
    if (form.password.trim() === '') {
      dispatch(setError(t('text.informPassword')));
      document.querySelector('#password').focus();
      return false;
    }
    return true;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      dispatch(setError(null));
      dispatch(fetchLogin(form));
    }
  }

  const handleClickForgotPassword = () => {
    dispatch(setError(null));
    onClickForgotPassword(true);
  };

  return (
    <>
      <Text>{t('text.loginLabel')}</Text>
      <Form data-testid="form-login" onSubmit={handleSubmit} autoComplete="off">
        <Input
          id="username"
          data-username="input"
          value={form.idProfissional}
          onChange={(e) => setForm({ ...form, idProfissional: e.target.value })}
          name="id"
          placeholder={t('professionalId')}
          width="100%"
        />
        <Input
          id="password"
          data-password="input"
          type="password"
          name="password"
          value={form.password}
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          placeholder={t('password')}
          width="100%"
        />
        <WrapperTextLink>
          <TextLink
            id="bnt-forgot-password"
            data-bnt-forgot-password="bnt"
            onClick={handleClickForgotPassword}
          >
            {t('text.forgotPassword')}
          </TextLink>
        </WrapperTextLink>

        <Message show={auth?.error} type="danger" data-error-message={auth?.error}>
          &times;
          {' '}
          {auth?.error}
        </Message>
        <Button
          id="bnt"
          data-bnt="bnt"
          type="submit"
          model={models.modelA}
          text="OK"
          margin="0 auto"
          state={(auth?.loginStatus === loginStatus.running) ? states.loading : states.default}
        />
      </Form>
    </>
  );
};

export default LoginForm;
