import { names } from 'components/Icon';
import { vitalTypes } from 'config/constants';

export function vitalSignDictionary(type) {
  switch (type) {
    case 'BLOOD_PRESSURE_2':
      return names.bloodPressure;
    case 'ARTERIAL_PRESSURE':
      return names.mediumBloodPressure;
    case vitalTypes.DIASTOLIC_ARTERIAL_PRESSURE:
      return names.diastolicBloodPressure;
    case vitalTypes.SYSTOLIC_ARTERIAL_PRESSURE:
      return names.systolicBloodPressure;
    case vitalTypes.HEART_RATE:
      return names.heartRate;
    case vitalTypes.OXYGEN_SATURATION:
      return names.saturation;
    case vitalTypes.RESPIRATORY_RATE:
      return names.respiratoryRate;
    case vitalTypes.TEMPERATURE:
      return names.temperature;
    case 'SAT O₂':
      return names.saturation;
    case 'FR':
      return names.respiratoryRate;
    case 'FC':
      return names.heartRate;
    case 'TEMP':
      return names.temperature;
    case 'PAD':
      return names.diastolicBloodPressure;
    case 'PAS':
      return names.systolicBloodPressure;
    case 'PAM':
      return names.mediumBloodPressure;
    default:
      return type;
  }
}
