import { dashboardActions } from 'config/constants';

const INITIAL_STATE = {
  data: [],
  loading: true,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case dashboardActions.LOAD_VITALSIGNS_DASHBOARD:
      return { ...state, data: action.payload, loading: false };
    case dashboardActions.TOGGLE_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
