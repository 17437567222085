export default function easterEgg(code, callback, debounce = 2000) {
  if (!code) throw new Error('é obrigatório informar codigo na função egg()');

  let timer;
  let characters = [];

  window.addEventListener('keydown', (event) => {
    clearTimeout(timer);
    const pressedKey = event.key;
    characters.push(pressedKey);
    const phrase = characters.join('');

    if (phrase.includes(code)) {
      callback();
      characters = [];
    }

    timer = setTimeout(() => {
      characters = [];
    }, debounce);
  });
}
