import styled from 'styled-components';
import { breakpoints, zIndex } from 'config/styles';

export const Header = styled.div`
  width: 100%;
  height: 30px;
`;

export const Body = styled.div`
  max-height: 70vh;
  overflow: auto;
  padding: 30px 30px 70px;
  position: relative;
  
  @media (min-width: ${breakpoints.sm}) {
    height: auto;
    overflow: unset;
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto 20px auto;
`;

export const Close = styled.button`
  width: 45px;
  height: 45px;
  padding: 0;
  color: ${({ theme }) => theme.colors.text.w000};
  font-weight: bold;
  border: 0;
  background: transparent;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  outline: none;
  cursor: pointer;
  z-index: ${zIndex.lv1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.w300};
  border-radius: 15px;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.4);
  height: auto;
  left: 4%;
  position: fixed;
  top: ${(props) => (props.show ? '5vh' : '-200%')};
  transition: top ease-in-out 0.4s;
  width: 92%;
  z-index: ${zIndex.lv3};
  
  @media (min-width: ${breakpoints.sm}) {
    left: calc(50% - 350px);
    width: 700px;
    top: ${(props) => (props.show ? '10vh' : '-200%')};
  }
`;

export const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0%;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.lv2};
  left: ${(props) => (props.show ? '0' : '-200%')};
  overflow-y: none;
`;

export const Headline = styled.h2`
  font-size: ${(props) => (props.size ? props.size : '20px')};
  color: #707070;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 15px;
  color: #707070;
  text-align: center;
  margin-top: 15px;
`;
