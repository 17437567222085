const INITIAL_STATE = {
  status: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'sidebar/TOGGLE_STATUS':
      return { ...state, status: !state.status };
    default:
      return state;
  }
}
