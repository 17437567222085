import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function SiteContainer({ children, margin }) {
  return (
    <Container margin={margin}>
      {children}
    </Container>
  );
}

SiteContainer.defaultProps = {
  margin: 'auto',
};

SiteContainer.propTypes = {
  children: PropTypes.element.isRequired,
  margin: PropTypes.string,
};
