import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connectionTypes } from 'config/constants';

import { useTranslation } from 'react-i18next';

import Icon, { names } from 'components/Icon';
import { socket } from 'services/socket';
import { ConnectionNotification, HeadLine } from './styles';

export default function ConnectionFailure() {
  const { t } = useTranslation();
  const { connection } = useSelector((s) => s.connection);
  const showIcon = connection ? (
    <Icon name={names.connectionOn} width="21px" height="21px" alt="Conexão On" />
  ) : (
    <Icon name={names.connectionOff} width="20px" height="20px" alt="Conexão Off" />
  );

  const dispatch = useDispatch();
  const user = useSelector((state) => state.root.user);
  const hospitalId = user?.hospital?._id;

  useEffect(() => {
    if (hospitalId) {
      socket.connect();
      socket.on('communication-failure', (data) => {
        if (data.communication) {
          dispatch({ type: connectionTypes.CONNECTION_ON });
        } else {
          dispatch({ type: connectionTypes.CONNECTION_OFF });
        }
      });
    }
  }, [dispatch, hospitalId]);
  return (
    <>
      <ConnectionNotification>
        {showIcon}
        <HeadLine>
          {
            connection ? t('conectionStatus.conected') : t('conectionStatus.desconected')
          }
        </HeadLine>
      </ConnectionNotification>
    </>
  );
}
