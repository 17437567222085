import styled from 'styled-components';
import { breakpoints } from 'config/styles';

export const Container = styled.div`
  display: block;
  margin: ${({ margin }) => margin};
  padding: 0 30px;
  
  @media (min-width: ${breakpoints.md}) {
    padding: 0 50px;
  }
`;
