import { i18n } from 'services/translate';
import { changeLanguage } from './actions';
import { options } from './constants';

const { useEffect } = require('react');
const { useDispatch, useSelector } = require('react-redux');

export const useLanguage = () => {
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.translate);

  useEffect(() => {
    const languageCache = window.localStorage.getItem('language');
    if (languageCache) {
      dispatch(changeLanguage(JSON.parse(languageCache)));
    } else {
      dispatch(changeLanguage(options[0]));
    }
  }, [dispatch]);

  useEffect(() => {
    if (translate) {
      i18n.changeLanguage(translate.language);
    }
  }, [translate]);
};
