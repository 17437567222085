import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Index({
  autoComplete,
  id,
  maxLength,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  state,
  type,
  value,
  width,
}) {
  return (
    <Container
      autoComplete={autoComplete}
      type={type}
      value={value}
      maxLength={maxLength}
      name={name}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      state={state}
      width={width}
    />
  );
}

Index.defaultProps = {
  autoComplete: 'on',
  id: '',
  maxLength: '',
  name: '',
  onBlur: () => { },
  onChange: () => { },
  placeholder: '',
  required: false,
  state: 'default',
  type: 'text',
  value: '',
  width: 'auto',
};

Index.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  state: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.string,
};
