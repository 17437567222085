import React from 'react';

import Header from 'components/Header';
import ConnectionFailure from 'components/ConnectionFailure';
import SelectLanguage from 'components/SelectLanguage';
import Footer from 'partials/Footer/Beds';
import Beds from 'containers/Beds';

import { Container } from 'config/styles';

export default function Home() {
  return (
    <>
      <Header>
        <SelectLanguage />
        <ConnectionFailure />
      </Header>
      <Container style={{ marginTop: 90 }}>
        <Beds />
        <Footer />
      </Container>
    </>
  );
}
