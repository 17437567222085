import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import LogoOrchestra from '__assets/img/logo-orchestra.png';
import PoweredBy from '__assets/img/poweredby.png';
import {
  Container,
  BoxLogin,
  Headline,
  Logo,
  RightBox,
  Boxtext,
  WrapperBox,
  MainContainer,
  Footer,
  Copy,
} from './styles';
import * as actions from './actions';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPasswordForm';
import { BoxTextItem } from './BoxTextItem';

function Login() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const logo = useSelector((state) => state.root.urlLogo);
  const { t } = useTranslation();

  document.title = forgotPassword ? t('title.resetPassword') : t('title.login');

  document.body.style = 'padding: 0px';

  function handleForgotPassword(didForgot) {
    setForgotPassword(didForgot);
  }

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = LogoOrchestra;
  };

  return (
    <Container>
      <MainContainer>
        <BoxLogin>
          <Logo
            data-img-logo={logo || LogoOrchestra}
            src={logo || LogoOrchestra}
            onError={handleImgError}
            alt=""
            loading="lazy"
            width="100"
            height="100"
          />
          <Headline>Orchestra</Headline>
          {
            forgotPassword ? <ForgotPassword onClickForgotPassword={handleForgotPassword} />
              : <LoginForm onClickForgotPassword={handleForgotPassword} />
          }
        </BoxLogin>
        <RightBox>
          <WrapperBox>
            <BoxTextItem />
          </WrapperBox>
        </RightBox>
      </MainContainer>
      <Footer>
        <Boxtext>
          <BoxTextItem color="black" isInFooter />
        </Boxtext>
        <Copy src={PoweredBy} alt="" width="164" height="80" />
      </Footer>
    </Container>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
