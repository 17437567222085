import { alarmConfigurationActions } from 'config/constants';

const INITIAL_STATE = {
  data: null,
  bed: null,
  loading: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case alarmConfigurationActions.LOAD_DATA:
      return { ...state, data: action.payload };
    case alarmConfigurationActions.LOAD_BED:
      return { ...state, bed: action.payload };
    case alarmConfigurationActions.TOGGLE_LOADING:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
