import io from 'socket.io-client';

export function socketIoFactory(namespace) {
  const socket = io(`${process.env.REACT_APP_CODFISH}${namespace}`, {
    path: '/hammerhead/socket.io',
    reconnection: true,
    autoConnect: false,
  });
  return socket;
}
