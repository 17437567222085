import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from 'pages/Login/actions';
import { useTranslation } from 'react-i18next';

import Button, { models } from 'components/Button';

import {
  Container,
  Header,
  Title,
  Body,
  Text,
  Footer,
  BtnGroup,
  Overlay,
} from './styles';

function Logout({ onClose, open, logout }) {
  const { t } = useTranslation();

  return (
    <>
      <Overlay show={open} />
      <Container show={open}>
        <Header>
          <Title>
            <h1>{t('logOut')}</h1>
          </Title>
        </Header>
        <Body>
          <Text>
            {t('text.logoutConfirmation')}
          </Text>
        </Body>
        <Footer>
          <BtnGroup>
            <Button width="fit-content" onClick={logout} data-modal-logout="sair" type="button" text={t('button.logOut')} model={models.modelB} />
            <Button width="fit-content" onClick={onClose} data-modal-cancel="voltar" type="button" text={t('button.back')} model={models.modelBSecondary} />
          </BtnGroup>
        </Footer>
      </Container>
    </>
  );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

export default connect(null, mapDispatchToProps)(Logout);
