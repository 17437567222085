export const options = [
  {
    id: 1,
    language: 'pt',
    flagName: 'pt',
    languageName: 'Português',
  },
  {
    id: 2,
    language: 'es',
    flagName: 'es',
    languageName: 'Español',
  },
];

export const languageActions = {
  CHANGE_LANGUAGE: 'LANGUAGE/CHANGE_LANGUAGE',
};
