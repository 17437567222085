import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Icon, { names } from 'components/Icon';

import { changeActiveMenuItem, changeActiveModule, toggleKeepCurrentActiveModule } from 'components/Module/actions';
import { Container } from './styles';

export default function ERPShortCut({ url }) {
  const { useErp, permissions } = useSelector((state) => state.root);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleClick() {
    dispatch(toggleKeepCurrentActiveModule(true));
    dispatch(changeActiveModule(0));
    dispatch(changeActiveMenuItem('ValidateVItalSigns'));
    history.push(url);
  }

  return (
    <Container>
      {
        useErp && permissions.send_erp_validation_get && <Icon name={names.erp} alt="" width="18" height="18" onClick={handleClick} />
      }
    </Container>
  );
}

ERPShortCut.defaultProps = {
  url: '',
};

ERPShortCut.propTypes = {
  url: PropTypes.string,
};
