import { usersActions } from 'config/constants';

const INITIAL_STATE = {
  data: [],
  userSelected: null,
  categories: [],
  statusSavingUser: 'idle',
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case usersActions.LOAD_USER:
      return { ...state, data: action.payload };
    case usersActions.SELECTED_USER:
      return { ...state, userSelected: action.payload };
    case usersActions.LOAD_CATEGORIES_USERS:
      return { ...state, categories: action.payload };
    case usersActions.SET_SAVING_USER_STATUS:
      return { ...state, statusSavingUser: action.payload };
    default:
      return state;
  }
}
