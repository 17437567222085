import { userLogsActions } from 'config/constants';

const INITIAL_STATE = {
  data: [],
  page: 1,
  limit: 10,
  totalPages: null,
  loading: true,
};

export default function Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userLogsActions.LOAD_DATA:
      return { ...state, data: action.payload };
    case userLogsActions.CHANGED_LIMIT:
      return { ...state, limit: action.payload };
    case userLogsActions.CHANGED_PAGE:
      return { ...state, page: action.payload };
    case userLogsActions.CHANGED_TOTALPAGES:
      return { ...state, totalPages: action.payload };
    case userLogsActions.LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
