/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
export const getEquipments = (devices) => {
  const equipmentType = (type) => {
    switch (type) {
      case 'monitor':
        return 'MONITOR';
      case 'pulmonary-ventilator':
        return 'VENTILATOR';
      case 'infusion-pump':
        return 'INFUSION_PUMP';
      default:
        return 'MONITOR';
    }
  };

  const getEquipmentObj = (equipmentKey, equipmentValue) => ({
    id: equipmentValue.id,
    interval: equipmentValue.interval,
    type: equipmentType(equipmentKey),
  });

  const equipmentsArray = [];
  if (devices) {
    Object.entries(devices)// .map(type => console.log('getEquipments', type[0], type[1]))
      .map((equipments) => (equipments[1].constructor === Array
        ? [...equipments[1].map((equipment) => equipmentsArray.push(getEquipmentObj(equipments[0], equipment)))]
        : equipments[1] && equipments[1].id !== null
          ? equipmentsArray.push(getEquipmentObj(equipments[0], equipments[1]))
          : null));
  }
  return equipmentsArray;
};

export function validateSickBedsData(response) {
  const data = [];
  const icuSickbeds = [];

  if (response.data.data.length > 0) {
    response.data.data.forEach((d) => {
      data.push({
        _id: d._id,
        name: d.name,
        status: !d.status ? '' : d.status,
        hasVitalSignToErp: false,
        pid: d.patient ? d.patient.pid : '',
        initials: d.patient ? d.patient.name : '',
        fullName: d.patient ? d.patient.fullName : '',
        patientId: d.patient ? d.patient._id : '',
        icu: d.icu._id,
        icuName: d.icu.name,
        vitalsigns: [],
        devices: d.devices,
      });
      icuSickbeds.push({
        _id: d._id,
        collectionTime: d.collectionTime,
        name: d.name,
        status: !d.status ? '' : d.status,
        icuId: d.icu._id,
        icuName: d.icu.name,
        devices: d.devices,
        equipments: getEquipments(d.devices),
      });
    });
  }
  return {
    data,
    icuSickbeds,
  };
}
