import { usersActions } from 'config/constants';
import api from 'services/api';
import { setNotification } from 'components/Notification/actions';
import { logout } from 'pages/Login/actions';

/*
  changePassword()
  @description: Edit user already existing
  @params: userSelected, user, copyUserSelected, userCurrent, userEdit, id, err and status
  @response: Receive new data for existing user
*/

export const changePassword = (id, formData) => async (dispatch) => {
  try {
    await api
      .patch(`/api/v2/user-password/${id}`, formData)
      .then(() => {
        dispatch(
          setNotification({
            type: 'success',
            headline: 'feedback.successfulyCreatedPassword',
            message: 'feedback.redirectingToLogin',
          }),
        );
        dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'success' });
        setTimeout(() => dispatch(logout()), 3000);
      })
      .catch((err) => {
        dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'error' });
        dispatch(
          setNotification({
            type: 'danger',
            headline: 'feedback.errorChangingPassword',
            message: err.response.data.errors[0].message,
          }),
        );
      });
  } catch (err) {
    dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'error' });
    dispatch(
      setNotification({
        type: 'danger',
        headline: 'feedback.errorChangingPassword',
        message: 'feedback.errorUnknown',
      }),
    );
  }
};
