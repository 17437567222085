import { bedsActions } from 'config/constants';

const INITIAL_STATE = {
  data: [],
  icus: [],
  loading: true,
  icusBeds: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case bedsActions.LOAD_BEDS:
      return { ...state, data: action.payload, loading: false };
    case bedsActions.ICUS_BEDS:
      return { ...state, icusBeds: action.payload, loading: false };
    case bedsActions.LOAD_ICUS:
      return { ...state, icus: action.payload };
    case bedsActions.LOADING:
      return { ...state, loading: !state.loading };
    default:
      return state;
  }
}
