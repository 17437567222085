import { zIndex } from 'config/styles';
import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 2em 2em 0 2em;
`;

export const Body = styled.div`
  position: relative;
  padding: 2em;
`;

export const Footer = styled.div`
  position: 100%;
  padding: 0.5em 2em 2em 2em;
`;

export const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0%;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.lv2};
  
  left: ${(props) => (props.show ? '0' : '-200%')};
  overflow-y: scroll;
`;

export const Container = styled.div`
  width: 100vw;
  max-width: 500px;
  height: auto;
  border-radius: 15px;
  position: fixed;
  top: ${(props) => (props.show ? '0' : '-200%')};
  left: 0;
  margin-top: 3.3em;
  transition: all ease-in-out 0.4s;
  background: ${({ theme }) => theme.colors.secondary.w350};
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.4);
  z-index: ${zIndex.lv3};
  opacity: ${(props) => (props.show ? '1' : '0')};

  @media (min-width: 500px){
    left: calc(50% - 250px);
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > h1 {
    margin-left: 0.4em;
    color: ${({ theme }) => theme.colors.text.w000};
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: 18px;
  text-align: center;
`;

export const BtnGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
