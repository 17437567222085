import api from './api';

const isDemo = (process.env.REACT_APP_IS_DEMO === 'true');

if (isDemo) {
  import('./intercept')
    .then((modules) => {
      const intercept = modules.default;
      intercept(api);
    });
}

export default api;
