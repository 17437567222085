import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading';
import RoutePrivate from 'components/RoutePrivate';
import Users from 'pages/Users';
import Home from 'pages/Home';

const Administrator = lazy(() => import('pages/Administrator'));
const AlarmLevelConfiguration = lazy(() => import('pages/AlarmConfiguration'));
const Bed = lazy(() => import('pages/Bed'));
const ConfirmationModal = lazy(() => import('components/ConfirmationModal'));
const CreateUti = lazy(() => import('pages/Administrator/CreateUti'));
const ExpiredSession = lazy(() => import('components/ExpiredSession'));
const Learning = lazy(() => import('pages/Learning'));
const Notification = lazy(() => import('components/Notification'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const PermissionDenied = lazy(() => import('components/PermissionDenied'));
const UserLogs = lazy(() => import('pages/UserLogs'));
const Utis = lazy(() => import('pages/Administrator/Utis'));

export default function Routes() {
  const { permissions } = useSelector((s) => s.root);

  return (
    <Router>
      <Suspense fallback={<div style={{ position: 'absolute', left: '50%', top: '50%' }}><Loading type="loading" circle /></div>}>
        <Notification />
        <ExpiredSession />
        <ConfirmationModal />
        <Switch>
          <Route path="/permission-denied" component={PermissionDenied} />
          <RoutePrivate
            authorization={permissions.sickbeds_get}
            path="/"
            exact
            component={Home}
          />
          <RoutePrivate
            authorization={permissions.sickbed_get}
            exact
            path="/bed/:id/:pid/:patientId"
            component={Bed}
          />
          <RoutePrivate
            authorization
            path="/learning"
            component={Learning}
          />
          <RoutePrivate
            authorization={permissions.change_alarms_values}
            exact
            path="/bed/alarm-level/change/:idConfig/:id/:pid/:patientId"
            component={AlarmLevelConfiguration}
          />
          <RoutePrivate
            authorization={permissions['user-logs_get']}
            path="/user-logs"
            component={UserLogs}
          />
          <RoutePrivate
            authorization={permissions.users_get}
            path="/users"
            component={Users}
          />
          <RoutePrivate
            authorization={permissions.hospital_update}
            path="/admin/configure"
            component={Administrator}
          />
          <RoutePrivate
            authorization={permissions.hospital_update}
            path="/admin/utis"
            exact
            component={Utis}
          />
          <RoutePrivate
            authorization={permissions.hospital_update}
            path="/admin/utis/create"
            component={CreateUti}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}
