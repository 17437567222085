import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from 'pages/Login/actions';
import { changeIcu } from 'containers/Beds/actions';

import Icon, { names } from 'components/Icon';

import {
  Footer, FooterRight, FooterIcon,
} from 'partials/Footer/styles';
import { Row, Col } from 'config/styles';

import VersionNumber from '../VersionNumber';

const FooterDefault = (props) => (
  <>
    <Footer>
      <Row>
        <Col md={50} />
        <Col md={50}>
          <FooterRight>
            <Icon name={names.orchestra1} width="215px" alt="Orchestra" />
            <VersionNumber />
            <button
              type="button"
              style={{
                background: 'transparent',
                border: 0,
                cursor: 'pointer',
              }}
              // eslint-disable-next-line react/destructuring-assignment
              onClick={props.logout}
            >
              <FooterIcon>
                <Icon name={names.logout} width="40px" height="40px" alt="Sair" />
              </FooterIcon>
            </button>
          </FooterRight>
        </Col>
      </Row>
    </Footer>
  </>
);
const mapStateToProps = (state) => ({
  icus: state.beds.icus,
  icuCurrent: state.beds.icuCurrent,
  sickbed: state.root.sickbed,
  user: state.root.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ changeIcu, logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FooterDefault);
