import { usersActions } from 'config/constants';
import api from 'services/api';
import store from 'config/store';
import { setNotification } from 'components/Notification/actions';

/*
  getCategoriesUsers()
  @description: Catch users users authorizated
  @params: d, err and status
  @response: null
*/

export const getCategoriesUsers = () => async (dispatch) => {
  try {
    const request = await api.get('/api/v2/user/authorization');
    const { data } = request.data;
    const categories = [];
    data.forEach((d) => {
      categories.push({ label: d.displayName, value: d._id });
    });

    dispatch({
      type: usersActions.LOAD_CATEGORIES_USERS,
      payload: categories,
    });
  } catch (err) {
    const status = err && err.response && err.response.status;
    dispatch(
      setNotification({
        type: 'danger',
        headline: `Erro ${status}`,
        message: 'Falha ao carregar combobox de permissões',
      }),
    );
  }
};

/*
  getUsers()
  @description: Get users list
  @params: err and satus
  @response: null
*/

export const getUsers = () => async (dispatch) => {
  try {
    const request = await api.get('/api/v2/user');
    const { data } = request.data;
    dispatch({ type: usersActions.LOAD_USER, payload: data });
  } catch (err) {
    const status = err && err.response && err.response.status;
    dispatch(
      setNotification({
        type: 'danger',
        headline: `Erro ${status}`,
        message: 'feedback.errorWhenLoadingUserList',
      }),
    );
  }
};

/*
  setMessage()
  @description: Show message if the user is edited
  @params: null
  @response: null
*/

export const setMessage = (message) => (dispatch) => {
  dispatch({ type: usersActions.CHANGED_MESSAGE, payload: message });
};

/*
  createUser()
  @description: Create new user
  @params: user, err and data
  @response: Receive user data
*/

export const createUser = (user) => async (dispatch) => {
  dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'running' });

  try {
    await api.post('/api/v2/user', user);
    dispatch(
      setNotification({
        type: 'success',
        headline: 'sucesso',
        message: 'feedback.successfullyCreatedUser',
      }),
    );
    dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'success' });
    dispatch(getUsers());
  } catch (err) {
    dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'error' });
    const data = err && err.response && err.response.data;
    if (data) {
      const { errors } = data;
      dispatch(
        setNotification({
          type: 'danger',
          headline: 'feedback.errorWhenCreatingUser',
          message: `${errors.message}`,
        }),
      );
    } else {
      dispatch(
        setNotification({
          type: 'danger',
          headline: 'Erro ao cadastrar',
          message: 'feedback.errorUnknown',
        }),
      );
    }
  }
};

/*
  changedUser()
  @description: Edit user already existing
  @params: userSelected, user, copyUserSelected, userCurrent, userEdit, id, err and status
  @response: Receive new data for existing user
*/

export const changedUser = (user, id) => async (dispatch) => {
  const { userSelected } = store.getState().user;
  const copyuserSelected = { ...userSelected };
  delete copyuserSelected.id;
  if (user.password !== undefined) {
    copyuserSelected.password = '';
    copyuserSelected.passwordConfirmation = '';
  } else {
    delete copyuserSelected.password;
    delete copyuserSelected.passwordConfirmation;
  }

  const userCurrent = JSON.stringify(copyuserSelected);
  const userEdit = JSON.stringify(user);

  if (userCurrent === userEdit) {
    dispatch(
      setNotification({
        type: 'warning',
        headline: 'Atenção',
        message: 'feedback.noChangesToSave',
      }),
    );
  } else {
    try {
      dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'running' });
      await api.put(`/api/v2/user/${id}`, user);

      dispatch(
        setNotification({
          type: 'success',
          headline: 'sucesso',
          message: 'feedback.successfullyUpdatedUser',
        }),
      );
      dispatch(getUsers());
      dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'success' });
    } catch (err) {
      dispatch({ type: usersActions.SET_SAVING_USER_STATUS, payload: 'error' });
      dispatch(
        setNotification({
          type: 'danger',
          headline: 'Erro ao alterar usuario',
          message: 'feedback.errorUnknown',
        }),
      );
    }
  }
};

/*
  removeUser()
  @description: Delete existing user
  @params: id, err and status
  @response: null
*/

export const removeUser = (id) => async (dispatch) => {
  try {
    await api.delete(`/api/v2/user/${id}`);
    dispatch(getUsers());
    dispatch(
      setNotification({
        headline: 'sucesso',
        type: 'success',
        message: 'feedback.successfullyDeletedUser',
      }),
    );
  } catch (err) {
    dispatch(
      setNotification({
        type: 'danger',
        headline: 'Falha ao remover usuário',
        message: 'feedback.errorUnknown',
      }),
    );
  }
};

/*
  setUserSelected()
  @description: Select user
  @params: null
  @response: null
*/

export const setUserSelected = (user) => (dispatch) => {
  dispatch({ type: usersActions.SELECTED_USER, payload: user });
};
