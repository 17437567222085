export const names = {
  adjust: 'adjust.svg',
  air: 'air.svg',
  alert: 'alert.svg',
  arrowLeft: 'arrowLeft.svg',
  arrowRight: 'arrowRight.svg',
  bloodPressure: 'bloodPressure.svg',
  config: 'config.svg',
  connectionOff: 'connectionOff.svg',
  connectionOn: 'connectionOn.svg',
  dashboard: 'dashboard.svg',
  diastolicBloodPressure: 'diastolicBloodPressure.svg',
  erp: 'erp.svg',
  es: 'es.svg',
  free: 'free.svg',
  heartRate: 'heartRate.svg',
  history: 'history.svg',
  home: 'home.svg',
  infusion: 'infusion.svg',
  level1: 'level1.svg',
  level2: 'level2.svg',
  level3: 'level3.svg',
  level4: 'level4.svg',
  level5: 'level5.svg',
  logout: 'logout.svg',
  mediumBloodPressure: 'mediumBloodPressure.svg',
  monitor: 'monitor.svg',
  orchestra1: 'orchestra1.svg',
  pt: 'pt.svg',
  respiratoryRate: 'respiratoryRate.svg',
  saturation: 'saturation.svg',
  systolicBloodPressure: 'systolicBloodPressure.svg',
  temperature: 'temperature.svg',
  user: 'user.svg',
};
