import { loginActions } from 'config/constants';
import { loginStatus } from './constants';

const INITIAL_STATE = {
  error: null,
  tokenInvalid: false,
  loginStatus: loginStatus.idle,
  forgotPasswordSent: null,
  showLogoutModal: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case loginActions.SET_ERROR:
      return { ...state, error: action.payload };
    case loginActions.SET_ERROR_FORGOT_PASSWORD:
      return { ...state, errorForgotPassword: action.payload };
    case loginActions.SET_FORGOT_PASSWORD_SENT:
      return { ...state, forgotPasswordSent: action.payload };
    case loginActions.TOKEN_INVALID:
      return { ...state, tokenInvalid: !state.tokenInvalid };
    case loginActions.LOGIN_STATUS:
      return { ...state, loginStatus: action.payload };
    case loginActions.TOGGLE_LOGOUT_MODAL:
      return { ...state, showLogoutModal: action.payload };
    default:
      return state;
  }
}
