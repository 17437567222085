import { actions } from './actions';

const INITIAL_STATE = {
  isModalOpen: false,
  preventLeavingPage: false,
  to: '',
  destinationComponent: '',
  shouldLogOut: false,
  shouldGoBack: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.CHANGE_DESTINATION_COMPONENT:
      return { ...state, destinationComponent: action.payload };
    case actions.CHANGE_URL_TO:
      return { ...state, to: action.payload };
    case actions.TOGGLE_MODAL:
      return { ...state, isModalOpen: action.payload };
    case actions.TOGGLE_PreventLeavingPage:
      return { ...state, preventLeavingPage: action.payload };
    case actions.TOGGLE_LOG_OUT:
      return { ...state, shouldLogOut: action.payload };
    case actions.TOGGLE_GO_BACK:
      return { ...state, shouldGoBack: action.payload };
    default:
      return state;
  }
}
