import { validateVitalSignsActions } from 'config/constants';

const INITIAL_STATE = {
  rows: [],
  message: null,
  loading: false,
  page: 1,
  limit: 10,
  limitsVitalSign: [],
  totalPages: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case validateVitalSignsActions.LOAD_VITALSIGNS_ERP:
      return { ...state, rows: action.payload ? action.payload : state.rows, loading: false };
    case validateVitalSignsActions.MESSAGE:
      return { ...state, message: action.payload };
    case validateVitalSignsActions.TOGGLE_LOADING:
      return { ...state, loading: action.payload };
    case validateVitalSignsActions.CHANGED_LIMIT:
      return { ...state, limit: action.payload ? action.payload : 10 };
    case validateVitalSignsActions.CHANGED_PAGE:
      return { ...state, page: action.payload ? action.payload : 1 };
    case validateVitalSignsActions.CHANGED_TOTALPAGES:
      return { ...state, totalPages: action.payload };
    case validateVitalSignsActions.CHANGED_VITALSIGNS_LIMITS:
      return { ...state, limitsVitalSign: action.payload };
    default:
      return state;
  }
}
