import styled, { keyframes, css } from 'styled-components';

const borderAnimation = keyframes`
  0% {
    border-color: ${({ theme }) => theme.colors.feedback.danger};
    opacity: 0
    transform: scale(1);
  }
  
  50% {
    border-color: ${({ theme }) => theme.colors.feedback.danger};
    opacity: 0.7;
    transform: scale(1.1);
  }

  100% {
    border-color: ${({ theme }) => theme.colors.feedback.danger};
    opacity: 0
    transform: scale(1);
  }
`;

export const ContainerBed = styled.div`
  width: 100%;
  height: 385px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 2px #b7b7b7b0 inset;

  ${({ status, theme }) => {
    switch (status.toLowerCase()) {
      case 'danger':
        return `
          background-color: ${theme.colors.feedback.danger};
        `;
      case 'inactive':
        return `
          background-color: ${theme.colors.secondary.w100};
        `;
      case 'free':
        return `
        background: ${theme.colors.primary.gradient};
        `;
      case 'on_holding':
        return `
          background-color: ${theme.colors.feedback.alert};
        `;
      default:
        return `
          background: ${theme.colors.secondary.gradient};
        `;
    }
  }}
`;

export const Header = styled.div`
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.secondary.w500};
  border-radius: 50%;
  border: ${({ theme, alert }) => (alert ? `4px solid ${theme.colors.feedback.danger}` : `2px solid ${theme.colors.secondary.w100}`)};
  position: absolute;
  left: 50%;
  margin-top: -30px;
  margin-left: -60px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;

  &:before {
    border: 4px solid ${({ theme, alert }) => (alert ? theme.colors.feedback.danger : theme.colors.primary.w200)};
    border-radius: 50%;
    bottom: -10px;
    content: "";
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
    animation: ${({ alert }) => (alert
    && css`
      ${borderAnimation} 1.5s infinite
    `)};
  }
`;

export const BedNull = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

export const BedNullTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.md};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.w000};
  font-weight: bold;
`;

export const BedNullImg = styled.img`
  width: 70px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 108px 0;
`;

export const BedNumber = styled.h2`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${({ theme }) => theme.fontSize.lg};
  overflow-x: hidden;
  font-weight: bold;
  width: 100px;
  margin: 0 auto;
  > a {
    color: ${({ theme }) => theme.colors.text.w000};
  }
`;

export const Initials = styled.span`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.w100};
  text-transform: uppercase;
`;

export const Pid = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${({ theme }) => theme.fontSize.xs};
  height: 19px;
  text-align: center;
  margin: 0 0 8px 0;
`;

export const VitalSign = styled.div`
  width: 100%;
  height: 33px;
  padding-bottom: 5px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 2px solid #646464;
  }

  ${({
    alarm, commFailure, lacking, theme,
  }) => {
    if (commFailure && !lacking) {
      switch (alarm.toLowerCase()) {
        case 'warning':
          return `
        background-color: ${theme.colors.feedback.alert}
        > span {
          color: #000;
        }
        `;
        case 'danger':
          return `background-color: ${theme.colors.feedback.danger};`;
        default:
          return '';
      }
    } else if (commFailure || lacking) {
      return `
        background-color: ${theme.colors.feedback.fail}
        > span {
          color: ${theme.colors.text.w000};
        }
        `;
    }

    switch (alarm.toLowerCase()) {
      case 'warning':
        return `
        background-color: ${theme.colors.feedback.alert}
        > span {
          color: ${theme.colors.text.w600};
        }
        > img {
          filter: invert(0.9);
        }
        `;
      case 'danger':
        return `background-color: ${theme.colors.feedback.danger};`;
      default:
        return '';
    }
  }}
`;

export const TextVitalSign = styled.span`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: 500;
`;

export const ScaleVitalSign = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: 500;
  margin-left: 3px;
`;
