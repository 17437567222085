import styled from 'styled-components';
import { breakpoints, zIndex } from 'config/styles';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(24, 23, 23, 0.5);
  position: fixed;
  top: 0;
  left: ${({ status }) => (status ? '0' : '-150%')};
  right: 0;
  bottom: 0;
  z-index: ${zIndex.lv2};
`;

export const Body = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-top: 30px;
`;

export const Header = styled.div`
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(85, 85, 85, 0.8);
  display: flex;
  height: 85px;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
`;

export const Container = styled.div`
  backdrop-filter: blur(10px);
  background: rgba(85, 85, 85, 0.8);
  width: 75%;
  height: 100%;
  position: fixed;
  z-index: ${zIndex.lv3};
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: ${({ status }) => (status ? '0' : '-150%')};
  transition: left 0.2s;
  @media (min-width: ${breakpoints.sm}) {
    width: 40%;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 25%;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 16.666%;
  }
`;

export const Headline = styled.h2`
  width: 190px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.text.w000};
  text-transform: uppercase;
`;

export const BtnClose = styled.button`
  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

export const Logo = styled.img`
  height: 28px;
  margin-right: 15px;
  margin-top: 10px;
`;
