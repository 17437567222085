import styled, { css } from 'styled-components';
import BgLogin from '__assets/img/login-bg.png';
import BgRight from '__assets/img/right-bg-login.png';
import { zIndex, breakpoints } from 'config/styles';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: transparent url(${BgLogin}) 0% 0% no-repeat padding-box;
  background-color: ${({ theme }) => theme.colors.secondary.w000};
  background-position: 0% 0%;
  background-size: cover;
  display: flex;
  overflow: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  position: relative;
  width: inherit;
    
  @media (max-height: 1000px) {
    height: 80vh;
  }
  @media (max-height: 550px) {
    height: 150vh;
  }
  @media (max-height: 400px) {
    height: 180vh;
  }
`;

export const Message = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => (props.type === 'danger' ? props.theme.colors.feedback.danger : props.theme.colors.primary.w100)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  text-align: center;
  margin-top: 10px;
`;

export const RightBox = styled.div`
  width: 50%;
  height: 437px;
  position: absolute;
  right: 0;
  margin-top: 10vh;
  background: url(${BgRight}) left top repeat;
  z-index: ${zIndex.lv1};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  @media (min-width: 1920px) {
    margin-top: 10vh;
  }
  @media (max-width: 500px) {
    height: 260px;
    margin-top: 28vh;
  }
`;

export const RightBoxItem = styled.div`
  width: min-content;
  height: min-content;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  ${(props) => (props.isInFooter
    ? css`
      border-right: ${(props) => (props.border ? '2px solid' : null)};
      border-color: ${(props) => (props.color ? props.color : '#fff')};
      padding: 0 20px;
      `
    : css`
      border-bottom: ${(props) => (props.border ? '2px solid' : null)};
      border-color: ${(props) => (props.color ? props.color : '#fff')};
      padding: 20px;
    
      @media only screen and (min-width: ${breakpoints.lg}) {
        border-bottom: none;
        border-right: ${(props) => (props.border ? '2px solid' : null)};
        border-color: ${(props) => (props.color ? props.color : '#fff')};
        padding: 0 20px;
      }
      `
  )};
`;

export const RIghtBoxText = styled.span`
  font: normal normal normal 35px Barlow;
  font-size: ${(props) => (props.size ? props.size : props.theme.fontSize.lg)};
  text-align: ${(props) => (props.right ? 'right' : 'left')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-weight: ${(props) => (props.right ? 'bold' : 'none')};
  flex-basis: 100%;
  letter-spacing: ${(props) => (props.right ? '0px' : '-6px')};
  margin-bottom: ${(props) => (props.right ? '0px' : '10px')};
  opacity: 1;
  text-align: left;
  display: flex;
  //display: contents;
  @media (min-width: 1280px) {
    font-size: ${(props) => (props.size ? props.size : props.theme.fontSize.lg)};
  }
  @media (min-width: 1920px) {
    font-size: ${(props) => (props.size ? props.size : props.theme.fontSize.lg)};
  }
  @media (max-width: 500px) {
    font-size: ${(props) => (props.size ? '35px' : props.theme.fontSize.xs)};
  }
`;

export const Boxtext = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (max-height: 900px) {
  } 
`;

export const WrapperBox = styled.div`
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  margin-right: 15%;

  
  @media only screen and (min-width: ${breakpoints.md}) {
    display: flex;
  } 

  @media only screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  } 
`;

export const Copy = styled.img`
  display: block;
  height: 80px;
  @media (max-width: 620px) {
    margin-top: 20px;
  }
  @media (min-width: 1920px) {
    margin-top: 30px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  flex-wrap: wrap;
  width: inherit;
  padding: 50px;  
  background-color: ${({ theme }) => theme.colors.secondary.w000};
  align-items: flex-start;
`;

export const Logo = styled.img`
  display: block;
  margin: -100px auto 0 auto;
  margin-bottom: 15px;
  width: 100px;
  background: ${({ theme }) => theme.colors.secondary.w100};
  /* padding: 10px; */
  border-radius: 8px;
`;

export const BoxLogin = styled.div`
  width: 500px;
  height: 500px;
  padding: 50px 100px;
  background-color: ${({ theme }) => theme.colors.secondary.w000};
  border-radius: 50%;
  margin: 10vh auto 0 auto;
  border: 10px solid #f5f3f2;
  position: absolute;
  z-index: ${zIndex.lv2};

  @media (min-width: 1920px) {
    width: 500px;
    height: 500px;
    padding: 50px 100px;
    margin-top: 10vh;
  }
  @media (max-width: 500px) {
    width: 310px;
    height: 310px;
    padding: 35px 30px;
    margin-top: 28vh;
  }
`;

export const Form = styled.form`
  margin: 20px auto;
  
  > input {
    margin-bottom: 10px;
  }
`;

export const Headline = styled.h2`
  font-size: ${(props) => (props.size ? props.size : props.theme.fontSize.md)};
  color: ${({ theme }) => theme.colors.text.w400};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const Text = styled.p`
  font-size: ${(props) => (props.size ? props.size : props.theme.fontSize.xs)};
  color: ${({ theme }) => theme.colors.text.w400};
  text-align: center;
  margin-top: 15px;

  @media (max-width: 500px) {
    font-size: 10px;
    margin-top: 5px;
  }
`;

export const TextLink = styled.a`
  color: ${({ theme }) => theme.colors.text.w400};
  font-size: ${(props) => (props.size ? props.size : props.theme.fontSize.xs)};
  text-decoration: underline;
  cursor: pointer;
`;

export const WrapperTextLink = styled.div`
  text-align: center;
`;
