/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function RoutePrivate({
  authorization,
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (authorization ? (
        <Component {...props} />
      ) : (
        <Redirect to="/permission-denied" />
      ))}
    />
  );
}
