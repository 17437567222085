import styled from 'styled-components';

export const Options = styled.div`
  display: none;
  left: 0;
  min-width: ${({ mini }) => (mini ? 'unset' : '140px')};
  padding-top: 10px;
  position: absolute;
  top: 30px;
  transition: 200ms;
  z-index: 9;
  

  :after {
    border-bottom:  ${({ theme }) => `6px solid ${theme.colors.secondary.w400}`};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: '';
    left: 10px;
    position: absolute;
    top: 4px;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;

  &:hover ${Options} {
    display: initial;
  }
`;

export const ActiveItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.text.w000};
  margin-left: 6px;
  text-transform: uppercase;
`;

export const Opition = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary.w400};
  cursor: pointer;
  display: flex;
  padding: 9px 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary.w350};
  }
`;
