import MockedSocket from 'socket.io-mock';

export class FakeSocket extends MockedSocket {
  constructor() {
    super();
    this.connectedMessage = 'O socketMock está conectado com sucesso';
    this.intervalCode = null;
    this.onConnectCallback = null;
  }

  connect() {
    // eslint-disable-next-line no-console
    console.info(this.connectedMessage);
    this.onConnectCallback();
  }

  disconnect() {
    clearInterval(this.intervalCode);
    // eslint-disable-next-line no-console
    console.log(`socket com setInterval ${this.intervalCode} desconectado com sucesso!`);
  }

  setIntervalCode(code) {
    this.intervalCode = code;
  }

  onConnect(fn) {
    this.onConnectCallback = fn;
  }
}
