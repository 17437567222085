import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Load } from '__assets/icons/loading.svg';
import { ReactComponent as Fail } from '__assets/icons/heart-break.svg';
import Circle from '__assets/icons/loading-circle.svg';
import { Container, Title, Img } from './styles';

export default function Loading({
  type, title, circle, height,
}) {
  const theme = useSelector((state) => state.theme);

  if (circle) {
    return (
      <Img
        src={Circle}
        style={{ width: '50px', display: 'block', margin: '10px auto' }}
        alt="Carregando..."
      />
    );
  }
  return (
    <Container height={height}>
      {
        type === 'loading' ? <Load fill={theme.colors.primary.w200} width="80px" height="80px" /> : <Fail width="80px" height="80px" />
      }
      <Title>{title}</Title>
    </Container>
  );
}
