import styled from 'styled-components';

export const Container = styled.input`
  background: ${({ theme }) => theme.colors.input.w100};
  border-radius: 50px;
  border: 1px solid ;
  border-color: ${({ theme, state }) => (state === 'error' ? theme.colors.feedback.danger : theme.colors.secondary.w050)};
  font-size: ${({ theme }) => theme.fontSize.sm};
  height: 45px;
  outline: none;
  padding: 0 15px;
  text-align: center;
  width: ${({ width }) => width};
  
  &:focus {
    border-color: ${({ theme, state }) => (state === 'error' ? theme.colors.feedback.danger : theme.colors.primary.w100)};
    filter: ${({ theme }) => `drop-shadow(0 0 6px ${theme.colors.shadow})`};
  }
`;
