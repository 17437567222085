import styled, { css } from 'styled-components';
import { breakpoints } from 'config/styles';
import { models, states } from './constants';

const roundedButton = css`
  background: ${({ theme, state }) => ((state === states.disabled) ? theme.colors.disabled : theme.colors.primary.w100)};
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.colors.text.w000};
  cursor: pointer;
  display: block;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
  height: 90px;
  margin: ${({ margin }) => margin};
  pointer-events: ${({ state }) => (state === states.default ? 'initial' : 'none')};
  transition: all 200ms ease;
  width: 90px;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
  }
  
  @media (min-width: ${breakpoints.sm}) {
    height: 130px;
    width: 130px;
  }
`;

const roundedButtonSecondary = css`
  background: ${({ theme, state }) => ((state === states.disabled) ? theme.colors.disabled : 'transparent')};
  border-radius: 50%;
  border:${({ theme }) => `2px solid ${theme.colors.primary.w100}`};
  color: ${({ theme, state }) => (state === states.default ? theme.colors.primary.w100 : theme.colors.text.w000)};
  cursor: pointer;
  display: block;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
  height: 130px;
  margin: ${({ margin }) => margin};
  pointer-events: ${({ state }) => (state === states.default ? 'initial' : 'none')};
  transition: all 200ms ease;
  width: 130px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
    color: ${({ theme }) => theme.colors.text.w000};
  }
`;

const flatButton = css`
  align-items: center;
  background: ${({ theme, state }) => ((state === states.disabled) ? theme.colors.disabled : theme.colors.primary.w100)};
  border-radius: 50px;
  border: none;
  color: ${({ theme }) => theme.colors.text.w000};
  cursor: pointer;
  display: flex;
  font-family: 'Abel';
  font-size: ${({ theme }) => theme.fontSize.xs};
  height: 38px;
  justify-content: center;
  margin: ${({ margin }) => margin};
  min-width: 120px;
  padding: 10px 20px;
  pointer-events: ${({ state }) => (state === states.default ? 'initial' : 'none')};
  text-align: center;
  transition: all 200ms ease;
  width: ${({ width }) => width};

  img {
    width: 28px !important;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
  }
`;

const flatButtonSecondary = css`
  align-items: center;
  background: ${({ theme, state }) => ((state === states.disabled) ? theme.colors.disabled : 'transparent')};
  border-radius: 50px;
  border:${({ theme }) => `2px solid ${theme.colors.primary.w100}`};
  color: ${({ theme, state }) => (state === states.default ? theme.colors.primary.w100 : theme.colors.text.w000)};
  cursor: pointer;
  display: flex;
  font-family: 'Abel';
  font-size: ${({ theme }) => theme.fontSize.xs};
  height: 38px;
  justify-content: center;
  margin: ${({ margin }) => margin};
  min-width: 120px;
  padding: 10px 20px;
  pointer-events: ${({ state }) => (state === states.default ? 'initial' : 'none')};
  text-align: center;
  transition: all 200ms ease;
  width: ${({ width }) => width};

  img {
    width: 28px !important;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
    color: ${({ theme }) => theme.colors.text.w000};
  }
`;

export const Container = styled.button`
  ${(props) => (props.model === models.modelA && roundedButton)}
  ${(props) => (props.model === models.modelASecondary && roundedButtonSecondary)}
  ${(props) => (props.model === models.modelB && flatButton)};
  ${(props) => (props.model === models.modelBSecondary && flatButtonSecondary)};
`;
