export const messages = {
  es: {
    translation: {
      module: {
        air: 'Aire',
        dialysis: 'Diálisis',
        infusion: 'Infusión',
        monitor: 'Monitor',
      },
      menu: {
        alarms: 'Alarmas',
        configERP: 'Frecuencia PEP',
        dashboard: 'Cuadro de mando',
        history: 'Historial',
        sendERP: 'Enviar PEP',
      },
      title: {
        air: 'Orchestra | Ventilador Pulmonar',
        alarms: 'Orchestra | Nivel de alarma',
        alarmsConfig: 'Orchestra | Configuración de alarmas',
        changePassword: 'Orchestra | Cambiar/alterar contraseña',
        dashboard: 'Orchestra | Cuadro de mando',
        erpConfig: 'Orchestra | Parámetros de envío al ERP',
        erpSend: 'Orchestra | Validación y envío al ERP',
        history: 'Orchestra | Historial del monitor',
        home: 'Orchestra | Camas',
        infusion: 'Orchestra | Bomba de infusión',
        login: 'Orchestra | Acceso',
        permissionDenied: 'Orchestra | ¡Permiso negado!',
        resetPassword: 'Orchestra | Restablecer la contraseña',
        users: 'Orchestra | Usuarios',
        usersLogs: 'Orchestra | Acceso de usuarios',
      },
      ssvv: {
        arterialPressure: 'Presión arterial',
        diastolicArterialPressure: 'Presión arterial diastólica',
        heartRate: 'Frec. cardíaca',
        mediumArterialPressure: 'Presión arterial media',
        oxygenSaturation: 'Sat. de oxígeno',
        respiratoryRate: 'Frec. respiratoria',
        systolicArterialPressure: 'Presión arterial sistólica',
        temperature: 'Temperatura',
      },
      abbreviation: {
        diastolicArterialPressure: 'PAD',
        diastolicArterialPressure_measureUnit: 'PAD (mmHg)',
        heartRate: 'FC',
        heartRate_measureUnit: 'FC (bpm)',
        mediumArterialPressure: 'PAM',
        mediumArterialPressure_measureUnit: 'PAM (mmHg)',
        oxygenSaturation: 'SAT',
        oxygenSaturation_measureUnit: 'SAT (%)',
        respiratoryRate: 'FR',
        respiratoryRate_measureUnit: 'FR (rpm)',
        systolicArterialPressure: 'PAS',
        systolicArterialPressure_measureUnit: 'PAS (mmHg)',
        temperature: 'TEMP',
        temperature_measureUnit: 'TEMP (ºC)',
      },
      historyMonitorSelect: {
        detailedSight: 'visualización detallada',
        mediumSight: 'visualización intermedia',
        wideSight: 'visualización general',
      },
      levels: {
        high: 'alto',
        low: 'bajo',
        normal: 'normal',
        veryHigh: 'muy alto',
        veryLow: 'muy bajo',
      },
      text: {
        accessDenied: 'Su autorización no es suficiente para acceder a esta página.',
        commercialMessage: 'Si desea convertirse en una Tele-UCI completa activando nuestros módulos adicionales para el monitoreo remoto de bombas de infusión y de ventiladores mecánicos, haga <0>clic aquí</0> o comuníquese con nuestro equipo de "Atención al Cliente"',
        criteriaForCreatingPassword: 'Criterios para crear una contraseña:',
        criteriaForCreatingPasswordNumberOfChars: 'Debe contener entre 6 y 12 caracteres',
        criteriaForCreatingPasswordTypesOfChars: 'Letras mayúsculas y minúsculas y números del 0 al 9',
        criteriaForCreatingPasswordDifferentPassword: 'Las contraseñas son diferentes',
        doYouWantRemoveUser: '¿Realmente desea remover a este usuario?',
        expiredSession: 'Su sesión expiró por inactividad. Es necesario ingresar nuevamente.',
        firstAccessText: 'Para continuar usando el sistema, por seguridad necesitamos que cree una nueva contraseña.',
        forgotPassword: 'Olvidé mi contraseña',
        goToInitialPage: 'Ir a la página inicial',
        informID: 'Favor informar su ID de profesional',
        informPassword: 'Favor informar su contraseña',
        loginLabel: 'Introduzca sus datos de acceso',
        logoutConfirmation: '¿Está seguro de que desea salir de Orchestra?',
        noChangesToSave: 'No hay cambios para guardar.',
        noDataFound: 'No se encontraron datos.',
        noLogsRegistred: 'No hay registros por el momento.',
        noRegistreFoundErp: 'No hay registros para validación y envío al ERP',
        noUserRegistred: 'No hay usuarios registrados',
        preset: 'Defina la frecuencia de envío del muestreo de datos a la historia clínica. Entre 15, 30 y 60 minutos (deslizar hasta la opción deseada)',
        setRange: 'Defina los valores de intervalo para el indicador:',
        typeAName: 'Favor digitar un nombre',
        typeAnEmail: 'Favor digitar un correo electrónico',
        typeARole: 'Favor digitar el cargo',
        typeCodeErp: 'Favor digitar el código del ERP',
        typePermissionLevel: 'Favor seleccionar el nivel de autorización',
        typeProfessionalID: 'Favor digitar el ID de profesional',
        typeProfessionalIDToRecoverPassword: 'Digite su ID de profesional para recibir una nueva contraseña',
        typeValidProfessionalID: 'Favor digitar un ID de profesional válido',
        typeXdigits: 'Por favor, su contraseña debe contener como mínimo {{count}} dígitos',
        typeYourPassword: 'Escribe tu contraseña',
        typeYourPasswordConfirmation: 'Ingrese la confirmación de su contraseña',
        userLogsTitle: 'Administrador: consulte abajo los LOG del sistema relacionados al uso de todos los usuarios registrados.',
      },
      conectionStatus: {
        conected: 'Conectividad ON',
        desconected: 'Conectividad OFF',
      },
      button: {
        back: 'Volver',
        cancel: 'Cancelar',
        logOut: 'Salir',
        save: 'Guardar',
        sendValidatedData: 'Enviar datos ya validados',
        yes: 'si',
      },
      bedStatus: {
        disabled: 'Desactivado',
        errorPID: 'Favor verificar datos del PID',
        free: 'Libre',
        onHolding: 'En espera',
      },
      alarmConfig: {
        cantBeEmpty: 'Este espacio no puede estar vacío',
        tooLowNotLowerThan: 'Valor muy bajo, no puede ser menor que',
        tooLowNotbiggerThan: 'Valor muy bajo, no puede ser más alto que el bajo',
        lowNotlowerThanTooLow: 'El valor bajo no puede ser menor o igual al bajo ',
        hightNotlowerThanLow: 'El valor alto no puede ser menor o igual al bajo',
        tooHightNotlowerThanHight: 'Valor muy alto, no puede ser menor o igual al alto',
        tooHightNotHigherThan: 'Valor muy alto, no puede ser mayor que',
      },
      confirmation: {
        keep: 'Continúe en la página',
        leave: 'Salir sin guardar',
        changesNotSaved: 'No se guardaron las modificaciones',
      },
      feedback: {
        configSaved: 'Configuraciones guardadas',
        errorChangingPassword: 'Error al cambiar la contraseña',
        errorDisassociatingPatient: 'Error al disociar al paciente.',
        errorSavingConfig: 'Error al guardar la configuración',
        errorSavingPatient: 'Error al salvar al paciente.',
        errorUnknown: 'error inesperado, inténtalo de nuevo',
        errorWhenChangingPresets: 'Error al cambiar los ajustes preestablecidos',
        errorWhenCreatingUser: 'Error al crear el usuario',
        errorWhenLoadingConfig: 'Error al cargar la configuración',
        errorWhenLoadingPresets: 'Error al cargar ajustes preestablecidos',
        errorWhenLoadingSickbedConfig: 'Error al cargar la información de la cama',
        errorWhenLoadingUserList: 'No se pudo cargar la lista de usuarios',
        errorWhileLoading: 'Error del servidor al intentar cargar vitals, inténtelo de nuevo.',
        errorWhileValidating: 'Error del servidor al intentar validar. Inténtalo de nuevo.',
        noChangesToSave: '¡UPS! no hay cambios para guardar',
        patientDisassociatedSuccessfully: '¡El paciente fue expulsado con éxito!',
        patientSuccessfullySaved: '¡Los datos del paciente se guardaron correctamente!',
        pleaseSelectPermissionLevel: 'Seleccione un nivel de permiso',
        pleaseSelectSomeDataToSend: 'Seleccione algunos datos para enviar.',
        pleaseTypeAName: 'Por favor ingrese un nombre',
        pleaseTypeAnEmail: 'Por favor ingrese un correo electrónico',
        pleaseTypeAnValidEmail: 'Por favor, ingrese un correo electrónico válido',
        pleaseTypeARole: 'Por favor ingrese una posición',
        pleaseTypeAValidName: 'Por favor ingrese un nombre valido',
        pleaseTypeERPCode: 'Ingrese el código ERP',
        pleaseTypeProfessionalId: 'Ingrese la identificación profesional',
        pleaseTypeSurname: 'Por favor ingrese un apellido',
        professionalIdNotExist: 'La identificación profesional ingresada no está registrada en el sistema',
        redirectingToLogin: 'Redirigiendo para iniciar sesión...',
        serverErrorTryAgain: 'Error del Servidor. Inténtalo de nuevo',
        successfullyCreatedUser: 'Usuario registrado',
        successfullyDeletedUser: 'Usuario eliminado',
        successfullyUpdatedUser: 'Usuario cambiado',
        successfullyValidated: 'Datos validados con éxito',
        successfulyCreatedPassword: 'Contraseña creada con éxito',
        updatedParams: 'Parámetros actualizados',
        weSentAnEmailToRecoverYourPassword: 'Te enviamos un correo electrónico con una nueva contraseña.',
        weSentAnEmailToRecoverYourPasswordTo: 'Enviamos un correo electrónico a {{email}} con una nueva contraseña.',
        whenTraingToUpdateSessionTime: 'Al intentar actualizar la hora de la sesión.',
      },
      action_plural: 'acciones',
      action: 'acción',
      accessDenied: '¡Acceso negado!',
      addUser: 'Agregar usuario',
      all: 'Todos',
      attention: 'atención',
      bed: 'Cama',
      bed_plural: 'Camas',
      changeICU: 'Cambio de UCI',
      codErp: 'Cód. del ERP',
      complacency: 'Complimiento',
      config: 'Config',
      createPassword: 'Crear contraseña',
      date: 'fecha',
      dateTime: 'Fecha/hora',
      delete: 'Excluir',
      disassociatingPatient: 'Disociar paciente',
      edit: 'Editar',
      email: 'Correo electrónico',
      empty: 'vacio',
      error: 'error',
      expiredSession: 'LA SESIÓN HA EXPIRADO',
      fullName: 'Nombre y apellido',
      hour: 'hora',
      incidence: 'incidencia',
      indicators: 'indicadores',
      indicatorsConfiguration: 'Configuración de indicadores',
      loading: 'carregando...',
      loadingSSVV: 'Cargando signos vitales...',
      logOut: 'Salir del sistema',
      measureUnit: 'Unidad de medida',
      modality: 'modalidad',
      newPassword: 'Nueva contraseña',
      operationsMode: 'Modo de operación',
      orchestrationSystem: 'Sistema de orquestación',
      password: 'Contraseña',
      passwordConfirmation: 'Confirmación de contraseña',
      permissionLevel: 'Nível de autorización',
      phone: 'Teléfono',
      professionalFullName: 'Nombre y apellido del profesional',
      professionalId: 'ID profesional',
      resistence: 'resistencia',
      role: 'función',
      roleOpt: 'Cargo',
      searchForPreset: 'Buscar preconfiguración',
      sector: 'Sector',
      select: 'Seleccionar',
      selectedPreset: 'Preconfiguración seleccionada',
      sendValidatedData: 'Enviar datos validados',
      success: 'éxito',
      standard: 'Estándar',
      time: 'tiempo',
      user: 'usuario',
      user_plural: 'usuarios',
      userLogs: 'registros de usuarios',
      value: 'valor',
      welcomeTo: 'bienvenido al',
    },
  },
};
