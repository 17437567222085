import { alarmLevelActions } from 'config/constants';

const INITIAL_STATE = {
  presets: [],
  selectedPreset: null,
  selectedBed: null,
  beds: [],
  unblockCarosel: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case alarmLevelActions.LOAD_BEDS:
      return { ...state, beds: action.payload };
    case alarmLevelActions.CHANGED_BED:
      return { ...state, selectedBed: action.payload, unblockCarosel: false };
    case alarmLevelActions.LOAD_ALARM_PRESETS:
      return { ...state, presets: action.payload };
    case alarmLevelActions.CHANGED_ALARM_PRESET:
      return { ...state, selectedPreset: action.payload };
    case alarmLevelActions.UNBLOCK_CHANG_BED:
      return { ...state, unblockCarosel: true };
    default:
      return state;
  }
}
