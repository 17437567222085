import React from 'react';
import PropTypes from 'prop-types';

import { names } from './constants';

export default function Icon({
  name, width, height, alt, loading, margin, onClick,
}) {
  return (
    <img
      role="presentation"
      onClick={onClick}
      width={width}
      height={height}
      alt={alt || name}
      loading={loading}
      src={`/assets/icons/${name}`}
      style={{ margin }}
    />
  );
}

Icon.defaultProps = {
  alt: 'icone',
  height: 'auto',
  loading: 'lazy',
  margin: '',
  onClick: () => {},
  width: 'auto',
};

Icon.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
};

export { names };
