// Tipos de status de carregamento
export const loadingStatus = {
  idle: 'idle',
  running: 'running',
  success: 'success',
  error: 'error',
};

export const resolutions = {
  amplo: 'amplo',
  intermediario: 'intermediario',
  detalhado: 'detalhado',
};

export const metricKeys = [
  'id',
  'temperature',
  'heartRate',
  'bloodPressureSystolic',
  'bloodPressureDiastolic',
  'o2Saturation',
  'respiratoryRate',
  'time',
];

export const metricInterface = {
  id: 123,
  temperature: 123,
  heartRate: 123,
  bloodPressureSystolic: 123,
  bloodPressureDiastolic: 123,
  o2Saturation: 123,
  respiratoryRate: 123,
  dateTime: '',
  time: '',
};

export const HistoryMonitorActions = {
  GET_DATA: 'HistoryMonitor/GET_DATA',
  LOAD_CHART: 'HistoryMonitor/LOAD_CHART',
  TOGGLE_INDICATOR: 'HistoryMonitor/TOGGLE_INDICATOR',
  TOGGLE_STATUS: 'HistoryMonitor/TOGGLE_STATUS',
  CHANGE_FILTER: 'HistoryMonitor/CHANGE_FILTER',
};
