import { CHANGE_THEME } from './actions';
import dark from './dark';
// import pink from './pink';
// import blue from './blue';

export default function themeReducer(state = dark, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return action.payload;
    default:
      return state;
  }
}
