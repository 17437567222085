import { Logger } from 'cloudwatch-front-logger';

const accessKeyId = process.env.REACT_APP_CLOUD_WATCH_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_CLOUD_WATCH_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_CLOUD_WATCH_REGION;
const logGroupName = process.env.REACT_APP_CLOUD_WATCH_LOG_GROUP_NAME;
// const logStreamName = 'fetchHistoryMonitorError';

export const logger = new Logger(accessKeyId, secretAccessKey, region, logGroupName);

logger.install();
