export const configActions = {
  SET_USEERP: 'config/SET_USEERP',
  SET_USER_LOGIN: 'config/SET_USER_LOGIN',
  LOGOUT: 'config/LOGOUT',
  SET_AUTH: 'config/SET_AUTH',
  CHANGED_ICU: 'config/CHANGED_ICU',
  SET_INTERACTIVE: 'config/SET_INTERACTIVE',
  RESET_INTERACTIVE: 'config/RESET_INTERACTIVE',
  SET_TIMER: 'config/SET_TIMER',
  RESET_TIMER: 'config/RESET_TIMER',
  SET_EXPERIED_SESSION: 'config/SET_EXPERIED_SESSION',
  SET_COLLECTION_TIME: 'config/SET_COLLECTION_TIME',
  SET_PERMISSIONS: 'config/SET_PERMISSIONS',
  SET_URL_LOGO: 'config/SET_URL_LOGO',
  SET_EQUIPMENTS: 'config/SET_EQUIPMENTS',
};

export const appConfigurations = {
  FEEDBACK_MESSAGE_IN_MILISECS: 3000,
};

export const expiredSessionConfigurations = {
  EXPIRE_TIME_LIMIT_IN_MINUTES: 30,
};

export const alarmConfigurationActions = {
  LOAD_DATA: 'alarmConfiguration/LOAD_DATA',
  LOAD_BED: 'alarmConfiguration/LOAD_BED',
  TOGGLE_LOADING: 'alarmConfiguration/TOGGLE_LOADING',
};

export const alarmLevelActions = {
  LOAD_BEDS: 'alarmLevel/LOAD_BEDS',
  CHANGED_BED: 'alarmLevel/CHANGED_BED',
  LOAD_ALARM_PRESETS: 'alarmLevel/LOAD_ALARM_PRESETS',
  CHANGED_ALARM_PRESET: 'alarmLevel/CHANGED_ALARM_PRESET',
  UNBLOCK_CHANG_BED: 'alarmLevel/UNBLOCK_CHANG_BED',
};

export const bedsActions = {
  CHANGED_SICKBED: 'beds/CHANGED_SICKBED',
  LOAD_BEDS: 'beds/LOAD_BEDS',
  ICUS_BEDS: 'beds/ICUS_BEDS',
  LOAD_ICUS: 'beds/LOAD_ICUS',
  LOAD_VITALSIGNS_ERP: 'beds/LOAD_VITALSIGNS_ERP',
  SEND_ERP: 'beds/SEND_ERP',
  MESSAGE: 'beds/MESSAGE',
  LOADING: 'beds/LOADING',
};

export const bedTypes = {
  FREE: 'FREE',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ON_HOLDING: 'ON_HOLDING',
  DANGER: 'DANGER',
};

export const notifyActions = {
  SHOW: 'notification/SET_CONFIG',
  TOGGLE_SHOW: 'notification/TOGGLE_SHOW',
  CHANGE_SHOW: 'notification/CHANGE_SHOW',
};

export const dashboardActions = {
  LOAD_VITALSIGNS_DASHBOARD: 'dashboard/LOAD_VITALSIGNS_DASHBOARD',
  TOGGLE_LOADING: 'dashboard/TOGGLE_LOADING',
};

export const loginActions = {
  SET_ERROR: 'login/SET_ERROR',
  SET_ERROR_FORGOT_PASSWORD: 'login/SET_ERROR_FORGOT_PASSWORD',
  SET_FORGOT_PASSWORD_SENT: 'login/SET_FORGOT_PASSWORD_SENT',
  TOKEN_INVALID: 'login/TOKEN_INVALID',
  LOGIN_STATUS: 'LOGIN/LOGIN_STATUS',
  TOGGLE_LOGOUT_MODAL: 'login/TOGGLE_LOGOUT_MODAL',
};

export const paramsSendERPActions = {
  LOAD_DATA: 'paramsSendERP/LOAD_DATA',
  MESSAGE: 'paramsSendERP/MESSAGE',
  LOADING: 'paramsSendERP/LOADING',
};

export const userLogsActions = {
  LOAD_DATA: 'userLogs/LOAD_DATA',
  CHANGED_LIMIT: 'userLogs/CHANGED_LIMIT',
  CHANGED_PAGE: 'userLogs/CHANGED_PAGE',
  CHANGED_TOTALPAGES: 'userLogs/CHANGED_TOTALPAGES',
  LOADING: 'userLogs/LOADING',
};

export const usersActions = {
  LOAD_USER: 'users/LOAD_USER',
  SELECTED_USER: 'users/SELECTED_USER',
  LOAD_CATEGORIES_USERS: 'users/LOAD_CATEGORIES_USERS',
  SET_SAVING_USER_STATUS: 'users/SET_SAVING_USER_STATUS',
};

export const validateVitalSignsActions = {
  LOAD_VITALSIGNS_ERP: 'validateVitalSigns/LOAD_VITALSIGNS_ERP',
  MESSAGE: 'validateVitalSigns/MESSAGE',
  TOGGLE_LOADING: 'validateVitalSigns/TOGGLE_LOADING',
  CHANGED_LIMIT: 'validateVitalSigns/CHANGED_LIMIT',
  CHANGED_PAGE: 'validateVitalSigns/CHANGED_PAGE',
  CHANGED_TOTALPAGES: 'validateVitalSigns/CHANGED_TOTALPAGES',
  CHANGED_VITALSIGNS_LIMITS: 'validateVitalSigns/CHANGED_VITALSIGNS_LIMITS',
};

export const vitalTypes = {
  OXYGEN_SATURATION: 'OXYGEN_SATURATION',
  SYSTOLIC_ARTERIAL_PRESSURE: 'SYSTOLIC_ARTERIAL_PRESSURE',
  DIASTOLIC_ARTERIAL_PRESSURE: 'DIASTOLIC_ARTERIAL_PRESSURE',
  RESPIRATORY_RATE: 'RESPIRATORY_RATE',
  BLOOD_PRESSURE: 'BLOOD_PRESSURE',
  TEMPERATURE: 'TEMPERATURE',
  HEART_RATE: 'HEART_RATE',
};

export const ventilatorActions = {
  LOAD_DATA: 'ventilator/LOAD_DATA',
  CHANGED_INDICATORS: 'ventilator/CHANGED_INDICATORS',
  CHANGED_REQUEST: 'ventilator/CHANGED_REQUEST',
  TOGGLE_LOADING: 'ventilator/TOGGLE_LOADING',
};

export const permissions = {
  SICKBEDS_GET: 'sickbeds_get',
  SICKBED_GET: 'sickbed_get',
  SEND_ERP_VALIDATION_GET: 'send_erp_validation_get',
  CHANGE_ICU: 'change_icu',
  USERS_GET: 'users_get',
  ALARMS_GET: 'alarms_get',
  MONITOR_HISTORY_GET: 'monitor_history_get',
  PARAMS_SEND_ERP_GET: 'params_send_erp_get',
  CHANGE_SEND_ERP_VALUES: 'params_send_erp_values',
  SEND_ERP: 'send_erp',
  CHANGE_PARAMS_SEND_ERP: 'change_params_send_erp',
  CREATE_USER: 'create_user',
  CHANGE_USER: 'change_user',
  DELETE_USER: 'delete_user',
  CHANGE_PRESET_ALARMS: 'change_preset_alarms',
  CHANGE_ALARMS_VALUES: 'change_alarms_values',
};

export const connectionTypes = {
  CONNECTION_ON: 'connect/ON',
  CONNECTION_OFF: 'connect/OFF',
};

export const adminActions = {};
