import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button, { models } from 'components/Button';
// import { models } from 'components/Button/constants'

import { removeUser } from '../actions';

import { Container, Buttons, Span } from './styles';

export default function ModalRemoveUser({
  userToRemove, toggleModalRemoveUser, setToggleModalRemoveUser,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClickOnYes() {
    dispatch(removeUser(userToRemove));
    setToggleModalRemoveUser(false);
  }

  return (
    <div>
      <Modal open={toggleModalRemoveUser} closeModal={() => setToggleModalRemoveUser(false)}>
        <Container>
          <Span>{t('text.doYouWantRemoveUser')}</Span>
          <Buttons>
            <Button id="confirmRemoveUser" text={t('button.yes')} onClick={handleClickOnYes} model={models.modelB} />
            <Button text={t('button.cancel')} onClick={() => setToggleModalRemoveUser(false)} model={models.modelBSecondary} />
          </Buttons>
        </Container>
      </Modal>
    </div>
  );
}

ModalRemoveUser.defaultProps = {
  userToRemove: undefined,
};

ModalRemoveUser.propTypes = {
  userToRemove: PropTypes.string,
  toggleModalRemoveUser: PropTypes.bool.isRequired,
  setToggleModalRemoveUser: PropTypes.func.isRequired,
};
