import styled from 'styled-components';
import { breakpoints } from 'config/styles';

export const Container = styled.div`
  display: grid;
  margin: 20px auto;
  max-width: 430px;
  padding: 0 20px;
  place-content: center;
  width: 100%;
  
  @media (min-width: ${breakpoints.xs}) {
    margin: 100px auto;
    padding: initial;
  }
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.colors.text.w000};
  margin-bottom: 40px;

`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: normal;
  margin-bottom: 10px;
`;

export const Text = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: normal;
  margin-bottom: 10px;
`;

export const List = styled.div`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-bottom: 40px;

  > ul {
    line-height: 26px;
    list-style-position: inside;
  }
`;

export const Form = styled.form`
  margin-bottom: 50px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.w000};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-bottom: 10px;
  margin-left: 13px;

  &:nth-of-type(2) {
    margin-top: 10px;
  }
`;
