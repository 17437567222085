import { createGlobalStyle } from 'styled-components';
import store from './store';
import 'config/font-face.css';

const { theme } = store.getState();

export const GlobalStyle = createGlobalStyle`
  html {
    overflow: overlay;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Barlow Semi Condensed', sans-serif !important;
  }

  body {
    background-color: ${theme.colors.secondary.w200};
    padding-bottom: 130px;
  }
  
  a {
    text-decoration: none;
  }
`;
