import styled from 'styled-components';
import { breakpoints } from 'config/styles';

export const BedsList = styled.div`
  display: grid;
  gap: 50px 8px;
  grid-template-columns: 1fr;
  margin: 40px auto 190px;
  place-content: center;
  
  @media (min-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 560px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${breakpoints.xs}) {
    margin: 40px auto 90px;
  }
  @media (min-width: ${breakpoints.sm}) {
    place-content: initial;
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(10, 1fr);
  }
`;
