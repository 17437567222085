import { socketIoFactory } from './socket';
import { FakeSocket } from './FakeSocket';

export function socketFactory(namespace = '') {
  const isDemo = (process.env.REACT_APP_IS_DEMO === 'true');

  if (isDemo) {
    const mockedSocket = new FakeSocket(namespace);

    import('./mock/mockProducer').then((modules) => {
      const mockProducer = modules.default;
      mockProducer(mockedSocket);
    });

    return mockedSocket;
  }

  return socketIoFactory(namespace);
}

// export const socketSickBed = socketFactory('sickbed');
// export const socketMonitor = socketFactory('monitor');
// export const socketComunication = socketFactory('bridge-communication');
export const socket = socketFactory();
