import React from 'react';
import { ReactComponent as IconClose } from '__assets/icons/icon-close.svg';
import { useSelector, useDispatch } from 'react-redux';

import Icon, { names } from 'components/Icon';

import {
  Container, Overlay, Body, Header, BtnClose, Headline,
} from './styles';

export default function SidebarOffCanvas({ children, title, removeLogo = false }) {
  const { status } = useSelector((state) => state.sidebarOffCanvasReducer);
  const dispatch = useDispatch();
  return (
    <>
      <Overlay
        status={status}
        onClick={() => dispatch({ type: 'sidebar/TOGGLE_STATUS' })}
      />
      <Container status={status}>
        <Header>
          <Headline>
            { removeLogo ? null : (<Icon name={names.orchestra1} width="215px" alt="Orchestra" />) }
            {title}
          </Headline>
          <BtnClose onClick={() => dispatch({ type: 'sidebar/TOGGLE_STATUS' })}>
            <IconClose width="16px" height="16px" fill="#fff" />
          </BtnClose>
        </Header>
        <Body>{children}</Body>
      </Container>
    </>
  );
}
