import React from 'react';
import {
  InvalidFullName,
  InvalidName,
  InvalidEmail,
  isEmpty,
  containsCharEspecial,
  maskPhoneNumber,
  rejectNumbersInString,
  rejectSpecialCharInString,
  InvalidIdProfessional,
} from 'services/utils';
import { Row, Col } from 'config/styles';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Select from 'components/Select';
import Button, { models, states } from 'components/Button';
import Input from 'components/Input';

import {
  FormControl, Label, Absolute,
} from '../styles';

export default function ModalForm({
  statusSavingUser,
  hospitalId,
  categoriesUsers,
  setNotification,
  createUser,
  changedUser,
  formData,
  setFormData,
  mode,
  user,
  toggleModal,
  closeModal,
}) {
  const { t } = useTranslation();
  function submitForm(e) {
    e.preventDefault();

    if (mode === 'create') {
      if (formData.idProfissional.trim() === '') {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeProfessionalId',
        });
      } else if (formData.erpCode.trim() === '') {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeERPCode',
        });
      } else if (isEmpty(formData.name)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeAName',
        });
      } else if (InvalidName(formData.name)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeAValidName',
        });
      } else if (InvalidFullName(formData.name)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeSurname',
        });
      } else if (isEmpty(formData.email)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeAnEmail',
        });
      } else if (InvalidEmail(formData.email)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeAnValidEmail',
        });
      } else if (isEmpty(formData.authorization._id)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseSelectPermissionLevel',
        });
      } else if (isEmpty(formData.companyFunction)) {
        setNotification({
          type: 'warning',
          headline: 'validação de campos',
          message: 'feedback.pleaseTypeARole',
        });
      } else {
        const payload = {
          name: formData.name,
          idProfissional: formData.idProfissional,
          authorization: {
            _id: formData.authorization._id,
          },
          companyFunction: formData.companyFunction,
          email: formData.email,
          erpCode: formData.erpCode,
          hospital: {
            _id: hospitalId,
          },
        };
        if (!isEmpty(formData.phoneNumber)) {
          payload.phoneNumber = formData.phoneNumber;
        }
        createUser(payload);
      }
    } else if (isEmpty(formData.idProfissional)) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typeProfessionalID'),
      });
    } else if (isEmpty(formData.erpCode)) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typeCodeErp'),
      });
    } else if (isEmpty(formData.name)) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typeAName'),
      });
    } else if (isEmpty(formData.email)) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typeAnEmail'),
      });
    } else if (isEmpty(formData.authorization._id)) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typePermissionLevel'),
      });
    } else if (isEmpty(formData.companyFunction)) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typeARole'),
      });
    } else if (!isEmpty(formData.password) && formData.password.length < 4) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: t('text.typeXdigits', { count: 4 }),
      });
    } else if (
      !isEmpty(formData.password)
      && isEmpty(formData.passwordConfirmation)
    ) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: 'Por favor, digite a senha novamente',
      });
    } else if (
      !isEmpty(formData.password)
      && formData.passwordConfirmation !== formData.password
    ) {
      setNotification({
        type: 'warning',
        headline: 'validação de campos',
        message: 'Por favor, as senhas devem ser iguais',
      });
    } else {
      let dataUpdated = {};
      if (formData.password === '') {
        dataUpdated = {
          name: formData.name,
          idProfissional: formData.idProfissional,
          erpCode: formData.erpCode,
          email: formData.email,
          authorization: {
            _id: formData.authorization._id,
          },
          phoneNumber: formData.phoneNumber,
          companyFunction: formData.companyFunction,
          hospital: {
            _id: hospitalId,
          },
        };
      } else {
        dataUpdated = {
          name: formData.name,
          idProfissional: formData.idProfissional,
          erpCode: formData.erpCode,
          email: formData.email,
          authorization: {
            _id: formData.authorization._id,
          },
          phoneNumber: formData.phoneNumber,
          companyFunction: formData.companyFunction,
          password: formData.password,
          passwordConfirmation: formData.passwordConfirmation,
          hospital: {
            _id: hospitalId,
          },
        };
      }
      changedUser(dataUpdated, formData._id);
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      open={toggleModal}
      title="Cadastrar novo usuário"
    >
      <form onSubmit={submitForm} autoComplete="off" style={{ position: 'relative' }}>
        <Row>
          <Col sm={26}>
            <FormControl>
              <Label>{t('professionalId')}</Label>
              <Input
                width="100%"
                autoComplete="off"
                type="text"
                name="username"
                value={formData.idProfissional}
                onChange={(e) => {
                  if (!InvalidIdProfessional(e.target.value)) {
                    setFormData({
                      ...formData,
                      idProfissional: e.target.value,
                    });
                  }
                }}
                placeholder={t('professionalId')}
              />
            </FormControl>
          </Col>
          <Col sm={24}>
            <FormControl>
              <Label>{t('codErp')}</Label>
              <Input
                width="100%"
                autoComplete="off"
                type="text"
                name="erpCode"
                value={formData.erpCode}
                onChange={(e) => {
                  if (!containsCharEspecial(e.target.value)) {
                    setFormData({ ...formData, erpCode: e.target.value });
                  }
                }}
                placeholder={t('codErp')}
              />
            </FormControl>
          </Col>
          <Col sm={50}>
            <FormControl>
              <Label>{t('professionalFullName')}</Label>
              <Input
                width="100%"
                autoComplete="off"
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({
                  ...formData,
                  name: rejectSpecialCharInString(rejectNumbersInString(e.target.value)),
                })}
                placeholder={t('fullName')}
              />
            </FormControl>
          </Col>
          <Col sm={66.666}>
            <FormControl>
              <Label>{t('email')}</Label>
              <Input
                width="100%"
                autoComplete="off"
                type="text"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                placeholder={t('email')}
              />
            </FormControl>
          </Col>
          <Col sm={33.333}>
            <FormControl>
              <Label>{`${t('phone')} (opcional)`}</Label>
              <Input
                width="100%"
                autoComplete="off"
                value={formData.phoneNumber}
                type="text"
                name="phoneNumber"
                maxLength="13"
                onChange={(e) => setFormData({
                  ...formData,
                  phoneNumber: maskPhoneNumber(e.target.value),
                })}
                placeholder="00 00000 0000"
              />
            </FormControl>
          </Col>
          <Col sm={50}>
            <FormControl>
              <Label>{t('permissionLevel')}</Label>
              <Select
                id="selectPermissionLevel"
                value={formData.authorization._id}
                options={
                  !user.isAdmin
                    ? categoriesUsers.filter((c) => c.label !== 'Administrador')
                    : categoriesUsers
                }
                placeholder={t('permissionLevel')}
                onChange={(item) => setFormData({
                  ...formData,
                  authorization: {
                    _id: item.value,
                  },
                })}
              />
            </FormControl>
          </Col>
          <Col sm={50}>
            <FormControl>
              <Label>{t('roleOpt')}</Label>
              <Input
                width="100%"
                autoComplete="off"
                type="text"
                name="companyFunction"
                value={formData.companyFunction}
                maxLength="30"
                onChange={(e) => {
                  if (!containsCharEspecial(e.target.value)) {
                    setFormData({
                      ...formData,
                      companyFunction: e.target.value,
                    });
                  }
                }}
                placeholder={t('roleOpt')}
              />
            </FormControl>
          </Col>
        </Row>
        <Absolute>
          <Button
            id="bnt"
            data-bnt="bnt"
            type="submit"
            model={models.modelA}
            text={t('button.save')}
            state={(statusSavingUser === 'running') ? states.loading : states.default}
          />
        </Absolute>
      </form>
    </Modal>
  );
}
