export const messages = {
  pt: {
    translation: {
      module: {
        air: 'Air',
        dialysis: 'Dialysis',
        infusion: 'Infusion',
        monitor: 'Monitor',
      },
      menu: {
        alarms: 'Alarmes',
        configERP: 'Frequência PEP',
        dashboard: 'Dashboard',
        history: 'Histórico',
        sendERP: 'Envio PEP',
      },
      title: {
        air: 'Orchestra | Ventilador Pulmonar',
        alarms: 'Orchestra | Níveis de Alarmes',
        alarmsConfig: 'Orchestra | Configuração de alarme',
        changePassword: 'Orchestra | Troca de senha',
        dashboard: 'Orchestra | Dashboard',
        erpConfig: 'Orchestra | Parâmetros de envio para ERP',
        erpSend: 'Orchestra | Validação e envio para ERP',
        history: 'Orchestra | Histórico do Monitor',
        home: 'Orchestra | Leitos',
        infusion: 'Orchestra | Bomba de infusão',
        login: 'Orchestra | Login',
        permissionDenied: 'Orchestra | Permissão negada!',
        resetPassword: 'Orchestra | Reset de senha',
        users: 'Orchestra | Usuários',
        usersLogs: 'Orchestra | Logs de usuários',
      },
      ssvv: {
        arterialPressure: 'Pressão Arterial',
        diastolicArterialPressure: 'Pressão Arterial Diastólica',
        heartRate: 'Freq. Cardiaca',
        mediumArterialPressure: 'Pressão Arterial Média',
        oxygenSaturation: 'Sat. de Oxigenio',
        respiratoryRate: 'Freq. Respiratória',
        systolicArterialPressure: 'Pressão Arterial Sistólica',
        temperature: 'Temperatura',
      },
      abbreviation: {
        diastolicArterialPressure: 'PAD',
        diastolicArterialPressure_measureUnit: 'PAD (mmHg)',
        heartRate: 'FC',
        heartRate_measureUnit: 'FC (bpm)',
        mediumArterialPressure: 'PAM',
        mediumArterialPressure_measureUnit: 'PAM (mmHg)',
        oxygenSaturation: 'SAT',
        oxygenSaturation_measureUnit: 'SAT (%)',
        respiratoryRate: 'FR',
        respiratoryRate_measureUnit: 'FR (rpm)',
        systolicArterialPressure: 'PAS',
        systolicArterialPressure_measureUnit: 'PAS (mmHg)',
        temperature: 'TEMP',
        temperature_measureUnit: 'TEMP (ºC)',
      },
      historyMonitorSelect: {
        detailedSight: 'visão detalhada',
        mediumSight: 'visão intermediária',
        wideSight: 'visão ampla',
      },
      levels: {
        high: 'alto',
        low: 'baixo',
        normal: 'normal',
        veryHigh: 'muito alto',
        veryLow: 'muito baixo',
      },
      text: {
        accessDenied: 'Você não tem permissão o suficiente para acessar essa página.',
        commercialMessage: 'Se você deseja se tornar uma Tele-UTI completa desbloqueando nossos módulos adicionais para monitoramento remoto de bombas (Orchestra Infusion) e ventiladores mecânicos (Orchestra Air), <0>clique aqui</0> ou fale com o nosso time de "Sucesso do Cliente"',
        criteriaForCreatingPassword: 'Critérios para criação de senha:',
        criteriaForCreatingPasswordNumberOfChars: 'Deve conter entre 6 a 12 caracteres',
        criteriaForCreatingPasswordTypesOfChars: 'Letras maiúsculas, minúsculas e números de 0 a 9',
        criteriaForCreatingPasswordDifferentPassword: 'As senhas são diferentes',
        doYouWantRemoveUser: 'Você realmente deseja remover este usuário?',
        expiredSession: 'Sua sessão foi encerrada por inatividade no sistema. É preciso efetuar login novamente',
        firstAccessText: 'Para continuar o uso do sistema, por segurança precisamos que você crie uma nova senha.',
        forgotPassword: 'Esqueci minha senha',
        goToInitialPage: 'Ir para a página inicial',
        informID: 'Por favor. Informe seu ID de profissional',
        informPassword: 'Por favor. Informe sua senha',
        loginLabel: 'Entre com seus dados para login',
        logoutConfirmation: 'Tem certeza que deseja sair do Orchestra?',
        noChangesToSave: 'Não há alterações para serem salvas',
        noDataFound: 'Nenhum dado encontrado',
        noLogsRegistred: 'Nenhum log registrado no momento',
        noRegistreFoundErp: 'Nenhum registo encontrado para validação e envio no ERP.',
        noUserRegistred: 'Nenhum usuário cadastrado',
        preset: 'Defina a frequência de envio da amostragem dos dados para o prontuário, entre 15, 30 e 60 minutos (arraste até a opção desejada).',
        setRange: 'Defina os valores de range para o indicador:',
        typeAName: 'Por favor, digite um nome',
        typeAnEmail: 'Por favor, digite um e-mail',
        typeARole: 'Por favor, digite um cargo',
        typeCodeErp: 'Por favor, digite código do ERP',
        typePermissionLevel: 'Por favor, selecione o nível de permissão',
        typeProfessionalID: 'Por favor, digite a ID de profissional',
        typeProfessionalIDToRecoverPassword: 'Digite sua ID de profissional para receber uma nova senha',
        typeValidProfessionalID: 'Por favor, digite uma ID de profissional válida',
        typeXdigits: 'Por favor, sua senha deve conter no minimo {{count}} digitos',
        typeYourPassword: 'Digite sua senha',
        typeYourPasswordConfirmation: 'Digite sua confirmação de senha',
        userLogsTitle: 'Administrador, acompanhe abaixo os LOGs do sistema referentes ao uso de todos os usuários cadastrados.',
      },
      conectionStatus: {
        conected: 'Conectividade ON',
        desconected: 'Conectividade OFF',
      },
      button: {
        back: 'Voltar',
        cancel: 'Cancelar',
        logOut: 'Sair',
        save: 'Salvar',
        sendValidatedData: 'Enviar dados validados',
        yes: 'sim',
      },
      bedStatus: {
        disabled: 'Desativado',
        errorPID: 'Por favor, verificar dados de PID',
        free: 'Livre',
        onHolding: 'Em espera',
      },
      alarmConfig: {
        cantBeEmpty: 'Este campo não pode ser vazio',
        tooLowNotLowerThan: 'O valor muito baixo não pode ser menor que',
        tooLowNotbiggerThan: 'O valor muito baixo não pode ser maior que o baixo',
        lowNotlowerThanTooLow: 'O valor baixo não pode ser menor ou igual a muito baixo',
        hightNotlowerThanLow: 'O valor alto não pode ser menor ou igual a baixo',
        tooHightNotlowerThanHight: 'O valor muito alto não pode ser menor ou igual a alto',
        tooHightNotHigherThan: 'O valor muito alto não pode ser maior que',
      },
      confirmation: {
        keep: 'Continuar na página',
        leave: 'Sair sem salvar',
        changesNotSaved: 'As modificações não foram salvas',
      },
      feedback: {
        configSaved: 'Configurações salvas',
        errorChangingPassword: 'Erro ao alterar senha',
        errorDisassociatingPatient: 'Error ao desassociar o paciente.',
        errorSavingConfig: 'Erro ao salvar as configurações',
        errorSavingPatient: 'Error ao salvar o paciente.',
        errorUnknown: 'erro inesperado, tente novamente',
        errorWhenChangingPresets: 'Erro ao alterar os presets',
        errorWhenCreatingUser: 'Erro ao criar usuário',
        errorWhenLoadingConfig: 'Erro ao carregar as configurações',
        errorWhenLoadingPresets: 'Erro ao carregar os presets',
        errorWhenLoadingSickbedConfig: 'Erro ao carregar informações do leito',
        errorWhenLoadingUserList: 'Falha ao carregar lista de usuários',
        errorWhileLoading: 'Erro no servidor ao tentar carregar os sinais vitais, por favor tente novamente.',
        errorWhileValidating: 'Erro no servidor, ao tentar validar. Por favor tente novamente.',
        noChangesToSave: 'Ops! não há nenhuma alteração para ser salva',
        patientDisassociatedSuccessfully: 'O paciente foi desassociado com sucesso!',
        patientSuccessfullySaved: 'O paciente foi salvo com sucesso!',
        pleaseSelectPermissionLevel: 'Por favor, selecione um nível de permissão',
        pleaseSelectSomeDataToSend: 'Por favor, selecione algum dado para envio.',
        pleaseTypeAName: 'Por favor, digite um nome',
        pleaseTypeAnEmail: 'Por favor, digite um e-mail',
        pleaseTypeAnValidEmail: 'Por favor, digite um e-mail válido',
        pleaseTypeARole: 'Por favor, digite um cargo',
        pleaseTypeAValidName: 'Por favor, digite um nome válido',
        pleaseTypeERPCode: 'Por favor, digite o Código do ERP',
        pleaseTypeProfessionalId: 'Por favor, digite o ID Profissional',
        pleaseTypeSurname: 'Por favor, digite um sobrenome',
        professionalIdNotExist: 'A ID de Profissional informada não está cadastrada no sistema',
        redirectingToLogin: 'Redirecionando para o login...',
        serverErrorTryAgain: 'Erro no servidor. Por favor tente novamente',
        successfullyCreatedUser: 'Usuário cadastrado',
        successfullyDeletedUser: 'Usuário excluído',
        successfullyUpdatedUser: 'Usuário alterado',
        successfullyValidated: 'Dados validados com sucesso',
        successfulyCreatedPassword: 'Senha criada com sucesso',
        updatedParams: 'Parâmetros atualizados',
        weSentAnEmailToRecoverYourPassword: 'Enviamos um e-mail com uma nova senha.',
        weSentAnEmailToRecoverYourPasswordTo: 'Enviamos um e-mail para {{email}} com uma nova senha.',
        whenTraingToUpdateSessionTime: 'Ao tentar atualizar tempo de sessão.',
      },
      action_plural: 'ações',
      action: 'ação',
      accessDenied: 'Acesso Negado!',
      addUser: 'Adicionar usuário',
      all: 'Todos',
      attention: 'atenção',
      bed: 'Leito',
      bed_plural: 'Leitos',
      changeICU: 'Troca de UTI',
      codErp: 'Cód. Do ERP',
      complacency: 'Complacência',
      config: 'cfg',
      createPassword: 'Criar senha',
      date: ' data',
      dateTime: 'Data/Hora',
      delete: 'Excluir',
      disassociatingPatient: 'Desassociar paciente',
      edit: 'Editar',
      email: 'E-mail',
      empty: 'vazio',
      error: 'erro',
      expiredSession: 'SESSÃO EXPIRADA',
      fullName: 'Nome e sobrenome',
      hour: 'hora',
      incidence: 'incidência',
      indicators: 'indicadores',
      indicatorsConfiguration: 'Configurações de indicadores',
      loading: 'carregando...',
      loadingSSVV: 'carregando sinais vitais...',
      logOut: 'Sair do sistema',
      measureUnit: 'Unidade de medida',
      modality: 'modalidade',
      newPassword: 'Nova senha',
      operationsMode: 'Modo de operação',
      orchestrationSystem: 'Sistema de Orquestração',
      password: 'Senha',
      passwordConfirmation: 'Confirmação de senha',
      permissionLevel: 'Nível de permissão',
      phone: 'Telefone',
      professionalFullName: 'Nome e sobrenome do profissional',
      professionalId: 'ID Profissional',
      resistence: 'resistencia',
      role: 'função',
      roleOpt: 'Cargo',
      searchForPreset: 'Busca por preset',
      sector: 'Ala',
      select: 'Selecionar',
      selectedPreset: 'Preset selecionado',
      sendValidatedData: 'Enviar dados validados',
      success: 'sucesso',
      standard: 'Padrao',
      time: 'tempo',
      user: 'usuário',
      user_plural: 'usuários',
      userLogs: 'Logs de usuários',
      value: 'valor',
      welcomeTo: 'seja bem vindo ao',
    },
  },
};
