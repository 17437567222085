import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';
import Routes from 'config/routes';
import { configActions } from 'config/constants';
import Login from 'pages/Login';
import * as actions from 'pages/Login/actions';
import { getLogo } from 'config/actions';

import ExpiredSession from 'components/ExpiredSession';
import FirstPasswordChange from 'containers/FirstPasswordChange';
import Loading from 'components/Loading';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLanguage } from 'components/SelectLanguage/hooks';

function paramsPermission(authorizations) {
  const authorization = {
    sickbeds_get: true,
    sickbed_get: true,
    send_erp_validation_get: true,
    change_icu: true,
    users_get: true,
    'user-logs_get': true,
    alarms_get: true,
    monitor_history_get: true,
    params_send_erp_get: true,
    params_send_erp_values: true,
    send_erp: true,
    change_params_send_erp: true,
    create_user: true,
    change_user: true,
    delete_user: true,
    change_preset_alarms: true,
    change_alarms_values: true,
    hospital_update: true,
    'pulmonary-ventilator': true,
  };
  authorizations.forEach((a) => {
    authorization[a.name] = a.permission;
  });
  return authorization;
}

function App({ user, tokenInvalid, decodeToken }) {
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  useLanguage();

  useEffect(() => {
    const token = window.localStorage.auth;
    if (token) {
      decodeToken(token);
    }
  }, [decodeToken]);

  useEffect(() => {
    dispatch(getLogo(user));
  }, [dispatch, user]);

  const token = window.localStorage.auth;

  if (user) {
    dispatch({
      type: configActions.SET_PERMISSIONS,
      payload: paramsPermission(user.authorizations),
    });
    if (user.generatedPassword) {
      return (
        <ThemeProvider theme={theme}>
          <ExpiredSession />
          <FirstPasswordChange user={user} />
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <Routes />
        </DndProvider>
      </ThemeProvider>
    );
  } if ((!user && tokenInvalid) || (!user && !tokenInvalid && !token)) {
    return (
      <ThemeProvider theme={theme}>
        <Login />
      </ThemeProvider>
    );
  }
  return <Loading height="80vh" type="loading" title="Iniciando orchestra..." />;
}

const mapStateToProps = (state) => ({
  user: state.root.user,
  tokenInvalid: state.auth.tokenInvalid,
  isAuth: state.root.isAuth,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
