import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  RightBoxItem,
  RIghtBoxText,
} from '../styles';

export const BoxTextItem = ({ color, right = true, isInFooter = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <RightBoxItem isInFooter={isInFooter} color={color} right={right} border>
        <RIghtBoxText color={color} right={right}>{t('orchestrationSystem')}</RIghtBoxText>
      </RightBoxItem>
      <RightBoxItem isInFooter={isInFooter}>
        <RIghtBoxText color={color} size="75px">IoMT</RIghtBoxText>
      </RightBoxItem>
    </>
  );
};
