import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeIcu } from 'containers/Beds/actions';
import Logout from 'components/Logout';
import Icon, { names } from 'components/Icon';
import {
  Footer,
  FooterLeft,
  FooterCenter,
  FooterRight,
  FooterIcon,
  FooterSideIcon,
  IconTitle,
  FooterIconAlign,
  FooterGrid,
} from 'partials/Footer/styles';
import { Link } from 'react-router-dom';

import { ReactComponent as IconUserLogs } from '__assets/icons/user-logs.svg';
import { ReactComponent as IconAdmin } from '__assets/icons/icon-admin.svg';

import SidebarOffCanvas from 'components/SidebarOffCanvas';
import Select, { models } from 'components/Select';
import { useTranslation } from 'react-i18next';
import VersionNumber from '../VersionNumber';

function FooterBed({
  icus, icuCurrent, permissions, user, changeIcu, logout,
}) {
  const [toggleLogout, setToggleLogout] = useState(false);
  const [icusSelect, setIcusSelect] = useState([]);
  const useErp = useSelector((state) => state.root.useErp);

  const { t } = useTranslation();

  useEffect(() => {
    const parseValues = [];
    if (icus && icus.length > 0) {
      icus.forEach((i) => {
        if (i.name !== undefined) {
          parseValues.push(
            { label: i.name, value: i._id, useErp },
          );
        }
      });
    }
    setIcusSelect(parseValues);
    const icuCache = window.localStorage.icu;
    if (!icuCache) {
      changeIcu(parseValues[0], user);
    } else if (!icuCurrent) {
      changeIcu(JSON.parse(icuCache), user);
    }
  }, [changeIcu, icuCurrent, icus, user, useErp]);

  const icuCache = window.localStorage.getItem('icu');

  const icuView = icuCache ? JSON.parse(icuCache) : icuCurrent;

  const windowSize = window.innerWidth;

  return (
    <>
      <Footer>
        <Logout open={toggleLogout} onClose={() => setToggleLogout(false)} />
        <FooterGrid>
          <FooterLeft>
            <div style={{ display: 'flex' }}>
              <Link
                data-footer-btn-beds
                to="/"
                title={t('bed_plural')}
              >
                <FooterIcon>
                  <Icon name={names.home} width="40px" height="40px" alt="Inicio" />
                </FooterIcon>
              </Link>
              {permissions.users_get ? (
                <Link
                  data-cy="users"
                  data-footer-bnt-user
                  to="/users"
                  title={t('user_plural')}
                >
                  <FooterIcon style={{ marginTop: '4px' }}>
                    <Icon name={names.user} width="30px" height="30px" alt="Configurar alertas" />
                  </FooterIcon>
                </Link>
              ) : (
                ''
              )}

              {permissions['user-logs_get'] ? (
                <Link
                  data-footer-bnt-userlog
                  to="/user-logs"
                  title={t('userLogs')}
                >
                  <FooterIcon style={{ marginTop: '4px' }}>
                    <IconUserLogs width="32" height="32" />
                  </FooterIcon>
                </Link>
              ) : (
                ''
              )}

              {permissions.hospital_update ? (
                <Link
                  data-footer-bnt-administrator
                  to="/admin/configure"
                  title="Administrar hospital"
                >
                  <FooterIcon style={{ marginTop: '4px' }}>
                    <IconAdmin width="32" height="32" fill="#fff" />
                  </FooterIcon>
                </Link>
              ) : (
                ''
              )}
            </div>
          </FooterLeft>
          <FooterCenter data-cy="select-icu">
            {permissions.change_icu ? (
              <Select
                onChange={(option) => changeIcu(option, user)}
                position="top"
                value={icuView && icuView.value}
                options={icusSelect}
                model={models.modelB}
              />
            ) : (
              <h3 data-select="icu">{icuView && icuView.label}</h3>
            )}
          </FooterCenter>

          <FooterRight>
            <Icon name={names.orchestra1} width="200px" alt="Orchestra" margin="0 10px 0 0" />
            <VersionNumber />
            <button
              type="button"
              data-logout="bnt"
              style={{
                background: 'transparent',
                border: 0,
                cursor: 'pointer',
                outline: 'none',
              }}
              onClick={() => setToggleLogout(true)}
              title={t('button.logOut')}
            >
              <Icon name={names.logout} width="40px" alt="Sair" />
            </button>
          </FooterRight>
        </FooterGrid>

        <SidebarOffCanvas>
          <div style={{ display: windowSize >= 1024 ? 'none' : 'grid' }}>
            <Link to="/">
              <FooterSideIcon>
                <FooterIconAlign>
                  <Icon name={names.home} width="40px" height="40px" alt="Inicio" />
                </FooterIconAlign>
                <IconTitle>Início</IconTitle>
              </FooterSideIcon>
            </Link>
            {permissions.users_get ? (
              <Link to="/users">
                <FooterSideIcon>
                  <FooterIconAlign
                    style={{ marginLeft: '.3em', marginRight: '.8em' }}
                  >
                    <Icon name={names.user} width="30px" height="30px" alt="Usuários" />
                  </FooterIconAlign>
                  <IconTitle>Usuários</IconTitle>
                </FooterSideIcon>
              </Link>
            ) : (
              ''
            )}

            {permissions['user-logs_get'] ? (
              <Link to="/user-logs">
                <FooterSideIcon>
                  <FooterIconAlign
                    style={{ marginLeft: '.3em', marginRight: '.8em' }}
                  >
                    <IconUserLogs width="32" height="32" />
                  </FooterIconAlign>
                  <IconTitle>Logs de usuários</IconTitle>
                </FooterSideIcon>
              </Link>
            ) : (
              ''
            )}

            {permissions.hospital_update ? (
              <Link to="/admin/configure" title="Administrar hospital">
                <FooterSideIcon>
                  <FooterIconAlign
                    style={{ marginLeft: '.3em', marginRight: '.8em' }}
                  >
                    <IconAdmin width="32" height="32" fill="#fff" />
                  </FooterIconAlign>
                  <IconTitle>Tela administrador</IconTitle>
                </FooterSideIcon>
              </Link>
            ) : (
              ''
            )}

            <button
              type="button"
              style={{
                background: 'transparent',
                border: 0,
                cursor: 'pointer',
                outline: 'none',
              }}
              onClick={logout}
            >
              <FooterSideIcon>
                <FooterIconAlign
                  style={{ marginLeft: '.2em', marginRight: '.6em' }}
                >
                  <Icon name={names.logout} width="40px" alt="Sair" />
                </FooterIconAlign>
                <IconTitle>Sair</IconTitle>
              </FooterSideIcon>
            </button>
          </div>
        </SidebarOffCanvas>
      </Footer>
    </>
  );
}

const mapStateToProps = (state) => ({
  icus: state.beds.icus,
  icuCurrent: state.root.icu,
  user: state.root.user,
  permissions: state.root.permissions,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ changeIcu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FooterBed);
