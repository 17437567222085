import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconWarning } from '__assets/icons/icon-warning.svg';
import { logout } from 'pages/Login/actions';
import { refreshToken } from 'config/actions';
import { configActions, expiredSessionConfigurations } from 'config/constants';
import { useInterval } from 'services/utils';
import { useTranslation } from 'react-i18next';

import Button, { models } from 'components/Button';

import {
  Container,
  Header,
  Title,
  Body,
  Text,
  Footer,
  Overlay,
} from './styles';

function ModalExpiredSession({ logout }) {
  const [expiredSession, setExpiredSession] = useState(false);
  const [minutesOfInactivity, setMinutesOfInactivity] = useState(0);
  const [interactive, setInteractive] = useState(false);
  const [lastInteractive, setLastInteractive] = useState(null);
  const dispatch = useDispatch();
  const { disallowExpiration } = useSelector((state) => state.root.user);

  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setLastInteractive('click');
    setInteractive(true);
    document.removeEventListener('click', handleClick);
    document.removeEventListener('contextmenu', handleClick);
  }, []);

  const handleKeyBoard = useCallback(() => {
    setLastInteractive('keyup');
    setInteractive(true);
    document.removeEventListener('keyup', handleKeyBoard);
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyBoard);
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleClick);
  }, [handleClick, handleKeyBoard]);

  const oneMinute = 60000;

  useInterval(() => {
    if (!disallowExpiration) {
      if (interactive && expiredSession === false) {
        setInteractive(false);
        setMinutesOfInactivity(0);
        dispatch(refreshToken());
        if (lastInteractive === 'click') {
          document.addEventListener('click', handleClick);
          document.addEventListener('contextmenu', handleClick);
        } else {
          document.addEventListener('keyup', handleKeyBoard);
        }
      } else {
        setMinutesOfInactivity(minutesOfInactivity + 1);
      }
    }
  }, oneMinute);

  useEffect(() => {
    if (!disallowExpiration) {
      if (minutesOfInactivity >= expiredSessionConfigurations.EXPIRE_TIME_LIMIT_IN_MINUTES) {
        setExpiredSession(true);
      }
    }
  }, [disallowExpiration, minutesOfInactivity]);

  useEffect(() => {
    if (expiredSession) {
      dispatch({ type: configActions.SET_EXPERIED_SESSION });
    }
  }, [dispatch, expiredSession]);

  return (
    <>
      <Overlay show={expiredSession} />
      <Container show={expiredSession}>
        <Header>
          <Title>
            <IconWarning width="35px" height="35px" />
            <h1>{t('expiredSession')}</h1>
          </Title>
        </Header>
        <Body>
          <Text>
            {t('text.expiredSession')}
          </Text>
        </Body>
        <Footer>
          <Button type="button" margin="0 auto" onClick={logout} text="OK" model={models.modelB} />
        </Footer>
      </Container>
    </>
  );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

export default connect(null, mapDispatchToProps)(ModalExpiredSession);
