import store from 'config/store';
import { createAction } from '@reduxjs/toolkit';

import { moduleActions } from './constants';

export const changeActiveModule = (currentIndex) => {
  const { modules } = store.getState();
  const payload = modules.list[currentIndex];

  return {
    type: moduleActions.CHANGE_ACTIVE_MODULE,
    payload,
  };
};

export const changeActiveMenuItem = (componentName, optionalList) => {
  const { modules } = store.getState();
  const navMenuList = optionalList || modules.active?.navMenu?.list;

  const payload = navMenuList.find((item) => (
    item.componentName === componentName
  ));

  return {
    type: moduleActions.CHANGE_ACTIVE_MENU_ITEM,
    payload,
  };
};

export const toggleKeepCurrentActiveModule = createAction(moduleActions.TOGGLE_KEEP_ORIGIN);
