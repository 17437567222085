import dayjs from 'dayjs';

import api from 'services/api';

export const actions = {
  SET_VITALSIGNSSHOW: 'ventilator/SET_VITALSIGNSSHOW',
  GET_VITALSIGNS_DONE: 'ventilator/GET_VITALSIGNS_DONE',
  GET_VITALSIGNS_ERROR: 'ventilator/GET_VITALSIGNS_ERROR',
};

export const setVitalSignsShow = (vitalSignsShow) => async (dispatch, getState) => {
  const { _id } = getState().root.user;
  window.localStorage.setItem(`ventilatorVitalSignsShow-${_id}`, JSON.stringify(vitalSignsShow));
  dispatch({ type: actions.SET_VITALSIGNSSHOW, vitalSignsShow });
};

export const toggleVitalSignsShow = (typeResult) => async (dispatch, getState) => {
  const vitalSignsShow = { ...getState().ventilator.vitalSignsShow };
  vitalSignsShow[typeResult].show = !vitalSignsShow[typeResult].show;
  dispatch(setVitalSignsShow(vitalSignsShow));
};

export const getVitalSignsShow = () => async (dispatch, getState) => {
  const { _id } = getState().root.user;
  const exists = window.localStorage.getItem(`ventilatorVitalSignsShow-${_id}`);
  if (exists) dispatch(setVitalSignsShow(JSON.parse(exists)));
};

export const getVitalSigns = (patientId) => async (dispatch, getState) => {
  if (patientId === null) return;
  let res;

  try {
    const url = `api/v2/pulmonary-ventilator/group/${patientId}`;
    res = await api.get(url);
  } catch (error) {
    dispatch({ type: actions.GET_VITALSIGNS_ERROR });
  }

  const payload = res?.data?.data ?? [];
  // eslint-disable-next-line consistent-return
  if (payload.length < 8) return dispatch({ type: actions.GET_VITALSIGNS_ERROR });
  const vitalSigns = { plots: {} };
  payload.reverse().forEach(({ devices, messageDateTime }) => {
    const date = dayjs(messageDateTime).format('DD/MM/YYYY');
    const time = dayjs(messageDateTime).format('HH:mm:ss');
    devices[0].equipments[0].vitalSigns.forEach((vs) => {
      const {
        typeResult, initials, value, units, lacking,
      } = vs;
      if (!vitalSigns[typeResult]) vitalSigns[typeResult] = [];
      if (units) {
        vitalSigns.plots[typeResult] = {
          typeResult, initials, value, units, status: lacking ? 'fail' : 'normal',
        };
      }
      vitalSigns[typeResult].push({ ...vs, date, time });
    });
  });
  // updating typeResult checkboxes in drawer
  const { vitalSignsShow: before } = getState().ventilator;
  const vitalSignsShow = {};
  Object.values(vitalSigns.plots).forEach((plot) => {
    const { typeResult } = plot;
    vitalSignsShow[typeResult] = before[typeResult] ? before[typeResult] : { ...plot, show: true };
  });
  dispatch(setVitalSignsShow(vitalSignsShow));
  dispatch({ type: actions.GET_VITALSIGNS_DONE, vitalSigns });
};
